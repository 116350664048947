
const Constants=
{
  SERVER_URL: "https://app.leadlazarus.ai",
  // SERVER_URL: "https://dev.leadlazarus.ai",

  // SERVER_URL: "http://192.168.0.141:5656",
  // SERVER_URL: "http://192.168.0.36:5656",
  // TEST_LEAD_ID: "a38caa79-d8f2-4283-8211-531914fae245" // Alexander
  // TEST_LEAD_ID: "631a4373-90bf-11ef-ac5e-000c29103954" // Paul

  // TEST_LEAD_ID: "7cd4bf5a-90de-11ef-ac5e-000c29103954" // Josh
}

module.exports = Constants;

// http://192.168.0.36:5656/dump_conversation/c8cb0332-f4ad-4fb4-9e4b-d8bd27be5e95_SMS

import React, {Component} from "react";

class Message extends Component
{
  constructor(props)
  {
    super();

    this.state=
    {
      message: props.message,
      message_index: props.key
    }
  }

  render()
  {
    const _this = this;

    const {message, message_index} = _this.state;

    if(message.text == null)
    {
      return;
    }

    const message_text =  message.text.value;

    // <img
    //   className="chat-bubble__left"
    //   src={message.avatar}
    //   alt="user avatar"/>

    // const bubble_class = message.name === "User" ? "chat-bubble-right" : "chat-bubble-left";
    // const username_class = message.name === "User" ? "username-right" : "username-left";

    const bubble_class = message.name === "Assistant" ? "chat-bubble-left" : "chat-bubble-right";
    const username_class = message.name === "Assistant" ? "username-left" : "username-right";

    const text = message.text.value + "\n";
    var paragraphs = [];
    var paragraph = "";
    for(var i=0; i < text.length; ++i)
    {
      paragraph += text[i];
      if((text[i] === "\n") /*|| (text[i] === "\r")*/ /*|| (i==(text.length-1))*/)
      {
        if(i < text.length-1)
        {
          paragraphs.push(paragraph);
          paragraph = "";
        }
      }
    }

    // Handle messaages with no line break character,
    // carriage return or period
    if(paragraphs.length == 0)
    {
        paragraphs.push(paragraph);
    }

    return (
      <React.Fragment>
        <div className={bubble_class} key={message_index}>
            <p className={username_class}>{message.name}</p>
            {paragraphs?.map((paragraph, index) => (
              <div key={index}>
                <p className="chat-bubble-paragraph">{paragraph}</p>
                <p className="line-break"/>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
};

export default Message;

import React, { Component } from "react";
import {Navbar, Nav, Form, FormControl, Button} from 'react-bootstrap';

import "../App.css"

class TopNavBar extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      app: props.app
    }

    this.logout = this.logout.bind(this);
  }

  logout(event)
  {
    event.preventDefault();
    this.state.app.logout();
  }

  render()
  {
    const view = this;

    return (
      <React.Fragment>
        <Navbar className="top-navbar">
          <Nav className="mr-auto">
          </Nav>
            <a className="navbar-link logout-link" onClick={this.logout}>Logout</a>
        </Navbar>
      </React.Fragment>
    );
  }
};

export default TopNavBar;

import React, {Component} from 'react';
import NavBar from "../components/NavBar";
import ChatBox from "../components/ChatBox";
import Axios from '../utils/Axios';
import ViewContainer from './ViewContainer';

class SMSDemoView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    const loggedin_user = props.app.state.loggedin_user;
    this.state=
    {
      app: props.app,
      loggedin_user: loggedin_user,
      client_name: props.client_name,
      demo_state: "Demo Not Running"
    };
  }

  async componentDidMount()
  {
    super.componentDidMount();

    const view = this;

    await view.get_logged_in_user(()=>
    {
      const {loggedin_user, client_name} = view.state;
      if(loggedin_user != null)
      {
        const url = "/api/get_client_lead/" + client_name + "_" + loggedin_user.id;
        Axios.Get(url, null, async(response)=>
        {
          const client_lead = response.data;
          view.setState({...view.state, client_lead: client_lead}, ()=>
          {
            const url = "/api/start_sms_conversation/" + view.state.client_lead.id;
            Axios.Get(url, null, async(response2)=>
            {
                await view.setState({loggedin_user, demo_state: "Demo SMS conversation started!"});
            });
          })
        });
      }
    });
  }

  render()
  {
    const view = this;

    const client_lead = view.state.client_lead;
    if(client_lead != null)
    {
      return (
        <div className="App">
          <NavBar />

        </div>
      );
    }
  }

};

export default SMSDemoView;

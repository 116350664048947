
import React from 'react';
import { Form } from 'react-bootstrap';

class FormControl extends React.Component
{
    render()
    {
        const { label, name, default_value, type, onChange, disabled, children } = this.props;

        return (
            <Form.Group controlId={name}>
                <Form.Label>{label}</Form.Label>
                {type === 'select' ? (
                    <Form.Control
                        as={type}
                        name={name}
                        defaultValue={default_value}
                        onChange={onChange}
                        disabled={disabled}
                    >
                        {children}
                    </Form.Control>
                ) : (
                    <Form.Control
                        type={type}
                        name={name}
                        defaultValue={default_value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                )}
            </Form.Group>
        );
    }
}

export default FormControl;

import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./css/Countdown.css";

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: ""
    };
    this.x = null;
    this.deadline = null;
  }
  count() {
    var now = new Date().getTime();
    var t = this.deadline - now;
    var dd = Math.floor(t / (1000 * 60 * 60 * 24));
    var hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var ss = Math.floor((t % (1000 * 60)) / 1000);

    var days = dd < 10 ? "0" + dd : dd;
    var hours = hh < 10 ? "0" + hh : hh;
    var minutes = mm < 10 ? "0" + mm : mm;
    var seconds = ss < 10 ? "0" + ss : ss;

    this.setState({ days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: "TIME IS UP"
      });
    }
  }
  componentDidMount() {
    this.deadline = new Date("Oct 08, 2020 21:00:00").getTime();

    this.x = setInterval(this.count, 1000);
  }

  render_box(label, value, extra_classes)
  {
    const classes = "box " + extra_classes;

    return (
      <Col className={classes}>
        <p id="day">{value}</p>
        <span className="textl">{label}</span>
      </Col>
    );
  }

  render()
  {
    const { days, seconds, hours, minutes } = this.state;

    return (
      <center>
        <div id="countdown">
          <Row>
             <Col lg={12} md={12} sm={12}>
              <h1>Coming Soon</h1>
            </Col>
          </Row>
          <Row>
              {this.render_box("Days", days, "")}
              {this.render_box("Hours", hours, "")}
              {this.render_box("Minutes", minutes, "")}
              {this.render_box("Seconds", seconds, "last-child")}
          </Row>
        </div>
      </center>
    );
  }
}

export default Countdown;

import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ReferrerTable from '../components/ReferrerTable';
import ReferrerModal from '../views/modals/ReferrerModal.js';
import ViewContainer from './ViewContainer';
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

class ReferrersView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      app: props.app,
      referrers: [],
      referrers_per_page: 10,      
      show_modal: false
    };

    this.show_modal = this.show_modal.bind(this);
    this.create_referrer = this.create_referrer.bind(this);
    this.on_close_referrer_modal = this.on_close_referrer_modal.bind(this);
    this.render_create_referrer_button = this.render_create_referrer_button.bind(this);
    this.render_modal = this.render_modal.bind(this);

    this.referrer_table = React.createRef();
  }

  show_modal(referrer)
  {
    const view = this;
    view.setState({...view.state, show_modal: true});
  }

  async create_referrer(referrer)
  {
    const view = this;

    const token = Cookies.get("token");
    const url = "/api/referrer/";

    await Axios.Post(url, referrer, async(response)=>
    {
      view.on_close_referrer_modal();
      view.referrer_table.current.fetch_data();
    });
  }

  on_close_referrer_modal()
  {
    const view = this;
    view.setState({...view.state, show_modal: false});
  }

  render_modal()
  {
    const view = this;

    const {show_modal} = view.state;
    if(show_modal)
    {
      return ( <ReferrerModal show_modal={true} parent_view={view}/> );
    }
  }

  render_create_referrer_button()
  {
    const view = this;

    return (
      <Button variant="primary" onClick={view.show_modal}>
        Add New Referrer
      </Button>
    );
  }

  render_view()
  {
    const view = this;

    const { loggedin_user, show_modal, selected } = view.state;
    if(loggedin_user != null)
    {
      return (
        <Container className="view-container">
          <Row className="my-4">
            <Col>
              <h1 className="referrers-view-table-title">Referrers</h1>
              {view.render_create_referrer_button()}
            </Col>
          </Row>

          <Row>
            <Col>
              <ReferrerTable loggedin_user={loggedin_user} ref={view.referrer_table }/>
              {view.render_modal()}
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default ReferrersView;

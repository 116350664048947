import React, { Component } from "react";

class ASButton extends Component
{
  render()
  {
    var self = this;

    return(
      <button type={self.props.type}
              id={self.props.id}
              className={self.props.classes + self.props.extra_classes}
              style={self.props.button_style}
              onClick={self.props.on_click}>
              {self.props.label}
      </button>
    );

  }
}

ASButton.defaultProps =
{
  id: "",
  type: "submit",
  classes: "btn btn-primary ",
  extra_classes: "",
  label: "Send",
  on_click: function(){}
}

export default ASButton;

import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import TopNavBar from '../components/TopNavBar';
import ASButton from '../components/ASButton.js';
import CardRow from '../components/CardRow.js';
import ViewContainer from './ViewContainer.js';
// import QRCodeLoginView from './QRCodeLoginView';

import wordmark from '../assets/wordmark.png';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';

import LoginView from './LoginView';

import "../App.css"

class LoggedOutView extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      app: props.app
    }

    if(!props.app.logged_in())
    {
      props.app.navigate_to_root();
    }

    this.app = props.app;
  }

  render_sidebar()
  {
  }

  render_top_navbar()
  {
  }

  render()
  {
    // <QRCodeLoginView app={this} logo={wordmark} on_login={this.on_login} app={this.state.app}/>

    const view = this;
    const app = this.props.app;

    return (
      <React.Fragment>
        <Container fluid >
          <Row>
          <Col lg={1} md={1} sm={1}/>
          <Col lg={10} md={10} sm={10} className="main-col">
             <Row className="full-height">
              <LoginView app={app} on_login={this.on_login}/>
             </Row>
          </Col>
          <Col lg={1} md={1} sm={1}/>
          </Row>
        </Container>

      </React.Fragment>
    );
  }

}

export default LoggedOutView;

import React, { Component } from "react";
import {Row, Col, Card, FormControl, Button} from 'react-bootstrap';
// import ConnectionSummaryCard from './ConnectionSummaryCard';
import CardView from './CardView';

class CardRow extends Component
{
  constructor(props)
  {
    super();

    this.state=
    {
      ...this.state,
      progress_1: props.progress_1,
      progress_2: props.progress_2,
      progress_3: props.progress_3,
      progress_4: props.progress_4
    }
  }

  render()
  {
    const view = this;

    // <CardView title="Users" value={14}/>
    // <CardView title="Credentials Issued" value={217}/>
    // <CardView title="Credentials Revoked" value={63}/>
    // <CardView title="Credentials Verified" value={3214}/>

    return (
      <React.Fragment>
      <div className="col-12 col-xl-12 ">
        <Row>

        </Row>
      </div>
      </React.Fragment>
    );
  }
};



CardRow.defaultProps=
{
  progress_1: -1,
  progress_2: -1,
  progress_3: -1,
  progress_4: -1,
};

export default CardRow;


import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import LeadsView from './LeadsView';
import CSVUpload from '../components/CSVUpload';
import DataSourceUpload from '../components/DataSourceUpload';
import LeadQualificationConfiguration from '../components/LeadQualificationConfiguration';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class LeadQualificationView  extends LeadsView
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            ...this.state,
            app: props.app,
            client_label: props.match.params.id,
            is_test: props.is_test
        };

        this.get_client_job = this.get_client_job.bind(this);
    }

    async componentDidMount()
    {
      await super.componentDidMount();

      const view = this;
      await view.get_client_job();
    }

    async get_client_job()
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        await Axios.Get('/api/client_job_by_client/', client.id, async(response)=>
        {
          if(response.data != null)
          {
            view.setState({client_job: response.data, clear_jobs: false}, async()=>
            {
                console.log("Got Client jobs: ", view.state.client_job);
                const client_job = view.state.client_job;
                await view.get_client(client_job.client_label);
            });
          }
        });
      }
    }
}

export default LeadQualificationView;


import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import ASComponent from './ASComponent';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import Papa from 'papaparse';
import base64 from 'base-64';

class LeadQualificationConfiguration extends ASComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
          parent_view: props.parent_view,
          client_label: props.client_label,
          job_description_status: "Please upload a job description",
          lead_list_status: "Please upload a csv file of leads",
          assistant_ready: "Assistant not ready",
          selected_file: null,
          is_test: props.is_test,
        };

        this.continue_button_enabled = this.continue_button_enabled.bind(this);
        this.check_config = this.check_config.bind(this);
    }

    async componentDidMount()
    {
      const view = this;

      super.componentDidMount();

      await view.get_client_by_label(view.state.client_label, async()=>
      {
        const {client} = view.state;
        if(client != null)
        {
          view.intervalId = setInterval(async() =>
          {
            await view.check_config();
          }, 500);
        }
      });
    }

    async componentWillUnmount()
    {
      const view = this;
      clearInterval(view.intervalId);
    }

    async check_config()
    {
      const view = this;

      const parent_view = view.props.parent_view;

      if(parent_view != null)
      {
        if(parent_view.has_leads())
        {
            await view.setState({...view.state, lead_list_status: "Leads are available"}, ()=>
            {
                //  REACT IS NOT SETTING THE FUCKING STATE CORRECTLY, SO I'LL HAVE TO FUCKING FORCE IT
                view.state.lead_list_status = "Leads are available";
                console.log("lead_list_status: ", view.state.lead_list_status);
            });
        }

        if(parent_view.has_jobdesc())
        {
          await view.setState({...view.state, job_description_status: "Job description ready"}, ()=>
          {
              view.state.job_description_status = "Job description ready";
          });
        }

        if(parent_view.assistant_is_ready())
        {
            view.setState({...view.state, assistant_ready: "Assistant is ready"}, ()=>
            {
                view.state.assistant_ready = "Assistant is ready";
            });
        }
      }
    }

    continue_button_enabled()
    {
      const view = this;

      const parent_view = view.state.parent_view;
      if(parent_view != null)
      {
        return parent_view.has_leads() && parent_view.has_jobdesc() && parent_view.assistant_is_ready();
      }
      return false;
    }

    render()
    {
      const view = this;

      const {is_test, job_description_status, lead_list_status, assistant_ready} = view.state;
      const client = view.state.parent_view.state.client;

      const title = is_test ? "Lead Qualification Configuration Test" : "Lead Qualification Configuration";

      return (
          <Container className="csv-upload-container">
              <h1>{title}</h1>
              {
                client && <Row>
                            <Col>
                              <h3>Client</h3>
                              <p style={{marginLeft: "20px"}}>{client.name}</p>
                            </Col>
                          </Row>
              }
              <Row>
                <Col>
                  <h3>Job Description</h3>
                  <div style={{marginLeft: "15px"}}>{job_description_status}</div>
                </Col>
                <Col>
                  <h3>Leads</h3>
                  <div style={{marginLeft: "15px"}}>{lead_list_status}</div>
                </Col>

                <Col>
                  <h3>Assistant</h3>
                  <div style={{marginLeft: "15px"}}>{assistant_ready}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                </Col>
                <Col>
                  <Button className="job-desc-button"
                          variant="primary"
                          type="submit"
                          disabled={!view.continue_button_enabled()}
                          onClick={view.state.parent_view.handle_continue}>
                      Continue
                  </Button>
                </Col>
              </Row>
          </Container>
      );
    }
}

export default LeadQualificationConfiguration;

import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import ClientTable from '../components/ClientTable';
import ClientModal from '../views/modals/ClientModal.js';
import ViewContainer from './ViewContainer';
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

class ClientsView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      app: props.app,
      clients: [],
      clients_per_page: 10,
      client_id: "569d05e7-8d95-11ef-bdd4-000c2923233c",
      show_modal: false,
      is_test: props.is_test
    };

    this.show_modal = this.show_modal.bind(this);
    this.create_client = this.create_client.bind(this);
    this.on_close_client_modal = this.on_close_client_modal.bind(this);
    this.render_create_client_button = this.render_create_client_button.bind(this);
    this.render_modal = this.render_modal.bind(this);

    this.client_table = React.createRef();
  }

  show_modal(client)
  {
    const view = this;
    view.setState({...view.state, show_modal: true});
  }

  async create_client(client)
  {
    const view = this;

    console.log("create_client: ", client);

    const url = "/api/client/";
    await Axios.Post(url, client, async(response)=>
    {
      view.on_close_client_modal();
      view.client_table.current.fetch_data();
    });
  }

  on_close_client_modal()
  {
    const view = this;
    view.setState({...view.state, show_modal: false});
  }

  render_modal()
  {
    const view = this;

    const {show_modal, is_test} = view.state;
    if(show_modal)
    {
      return ( <ClientModal is_test={is_test} show_modal={true} parent_view={view}/> );
    }
  }

  render_create_client_button()
  {
    const view = this;

    return (
      <Button variant="primary" onClick={view.show_modal}>
        Add New Client
      </Button>
    );
  }

  render_view()
  {
    const view = this;

    const { loggedin_user, show_modal, selected } = view.state;
    if(loggedin_user != null)
    {
      return (
        <Container className="view-container">
          <div className="table-view">
            <Row className="my-4">
              <Col>
                <h1 className="clients-view-table-title">Clients</h1>
                {view.render_create_client_button()}
              </Col>
            </Row>

            <Row>
              <Col>
                <ClientTable is_test={view.props.is_test}  ref={view.client_table }/>
                {view.render_modal()}
              </Col>
            </Row>
          </div>
        </Container>
      );
    }
  }
}

export default ClientsView;


import React, { Component } from "react";
import ASComponent from './ASComponent';
import ProgressBar from './ProgressBar';
import {Row, Col, Card, FormControl, Button} from 'react-bootstrap';

class CardView extends ASComponent
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      title:props.title,
      progress: props.progress,
      value: props.value,
    }

    this.render_view = this.render_view.bind(this);
  }

// <h5 style={{"margin-left":"10px"}}> Test Links</h5>

// <Row>
//   <h1 className="card-view-value">{this.state.value}</h1>
// </Row>

  render_view()
  {
    const view = this;

    const progress = parseInt(view.state.progress);

    return (
      <React.Fragment>
        <Row>
          <h2 className="card-view-title">{this.state.title}</h2>
        </Row>
        <Row>
          <h1 className="card-view-value">{this.state.value}</h1>
        </Row>
        {(progress > -1) && <ProgressBar progress={progress}/>}

      </React.Fragment>
    );
  }

  render()
  {
    const view = this;

    // <div className="card">

    // <Card className="dashboard-card-body">
    //   {view.render_view()}
    // </Card>

    return (
      <React.Fragment>
        <Col className="card-col">
          <Card className="dashboard-card-body">
             {view.render_view()}
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

CardView.defaultProps=
{
  title: ""
}

export default CardView;

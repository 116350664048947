import React from 'react';
import Axios from '../utils/Axios';
import LinkTable from './LinkTable';
import Constants from '../Constants.js';

import "./css/Table.css"

class UserGroupsTable extends LinkTable
{
    constructor(props)
    {
      super(props);

      var view = this;

      view.state =
      {
        ...this.state,
        title:props.title,
        url: props.url,
        body_array: props.body_array,
        header_array: props.header_array,
        search_url: '/find_user_groups/',
        owner_view:props.owner_view,
        max_num_results: props.max_num_results,
        max_num_page_results: props.max_num_page_results,
      }
    }

    format_row(row)
    {
      if(row != null)
      {
        var row_data = [];

        row_data.push(row.name);
        row_data.push(row.description);

        return row_data;
      }
      return null;
    }

    format_search_result(results)
    {
      var formatted = [];

      for(let user_group of results)
      {
        const table_user_group = [];

        table_user_group.push(user_group.name);
        table_user_group.push(user_group.description);
        formatted.push(table_user_group);
      }
      return formatted;
    }

    render_table_row(prop,key)
    {
        var view = this;

        return (
            <tr className="link_table_row" key={key} onClick={(e)=>{view.handle_click(e, view, prop)}}>
            {
                prop.map((prop,key)=>
                {
                    return ( <td  key={key}>{prop}</td>);
                })
            }
            </tr>
        )
    }
}

UserGroupsTable.defaultProps =
{
  ...LinkTable.defaultProps,
  url: "/user_groups/",
  search_url: '/find_user_groups/',
  item_url: '/user_group/',
  header_array:["Name","Description"],
  start_index: 0
}

export default UserGroupsTable;

import React, { Component } from "react";
import {Row, Col, Form, Button} from 'react-bootstrap';
import $ from "jquery";

import industry_rates from './industry-rates.js';

import "./App.css";


class RevenueCalculator extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      neurodigi_api_data: props.neurodigi_api_data,
      visitors: null,
      conversionRate: null,
      avgRevenue: null,
      leadAge: null,
      industry: "unknown",
      potential_revenue: 0.00
    }

    this.handle_input_change = this.handle_input_change.bind(this);
    this.render_input = this.render_input.bind(this);
    this.calculate_revenue = this.calculate_revenue.bind(this);
  }

  async componentDidMount()
  {
      const view = this;

  }

  calculate_revenue()
  {
    const view = this;

    const {visitors,  industry } = view.state;
    const conversionRate = parseInt( view.state.conversionRate);
    const avgRevenue = parseInt( view.state.avgRevenue);
    const leadAge = parseInt( view.state.leadAge);

    if(isNaN(visitors) || visitors <= 0)
    {
        // alert("Please enter a valid number of cold leads.");
        return;
    }

    if(isNaN(conversionRate) || conversionRate <= 0 || conversionRate > 100)
    {
        // alert("Please enter a valid conversion rate (0-100%).");
        return;
    }

    if(isNaN(avgRevenue) || avgRevenue <= 0)
    {
        // alert("Please enter a valid average revenue per lead.");
        return;
    }

    if(isNaN(leadAge) || leadAge < 0)
    {
        // alert("Please enter a valid lead age in days.");
        return;
    }

    const selected_rates = industry_rates[industry] || industry_rates.unknown;
    // console.log("selected_rates: ", selected_rates);

    let adjustedConversionRate = conversionRate;

    // Adjust conversion rate based on lead age and selected industry
    if (leadAge >= 365)
    {
        adjustedConversionRate *= selected_rates["1+"] / 100;
    }
    else if (leadAge >= 180)
    {
        adjustedConversionRate *= selected_rates["6-12 months"] / 100;
    }
    else if (leadAge >= 30)
    {
        adjustedConversionRate *= selected_rates["3-6 months"] / 100;
    }

    const leads = (visitors * adjustedConversionRate) / 100;
    const potential_revenue = (leads * avgRevenue).toFixed(2);

    view.setState({...view.state, potential_revenue});
  }

  handle_input_change(event)
  {
      const view = this;

      const { value } = event.target;

      var name;
      const index = event.target.selectedIndex;
      if(index != null)
      {
        const optionElement = event.target.childNodes[index]
        name =  optionElement.getAttribute('name');
      }
      else
      {
        name =  event.target.attributes.id.value;
      }

      view.setState({ ...view.state, industry:name, [name]: value }, ()=>
      {
        // view.calculate_revenue();
      });
  }


  render_input(label, id, placeholder)
  {
    const view = this;

    return (
      <React.Fragment>
        <label className="revenue-calculator-input-title" for="visitors">{label}</label>
        <input type="number" id={id} placeholder={placeholder} required onChange={view.handle_input_change}/>
        <br/>
      </React.Fragment>
    );
  }

  render()
  {
    const view = this;

    const {potential_revenue} = view.state;

    return (

      <React.Fragment>

        <Row id="ll-revenue-calculator-container">
          <Col md={3}> </Col>
          <Col md={6}>

            <center><h2 className="revenue-calculator-title">Potential Revenue Calculator</h2></center>

              <label className="revenue-calculator-input-title" htmlFor="industry">Select Industry</label>
              <select id="industry" onChange={view.handle_input_change}>
                  <option name="unknown">Unknown</option>
                  <option name="carDealership" >Car Dealership</option>
                  <option name="realEstate">Real Estate</option>
                  <option name="insurance">Insurance</option>
                  <option name="homeServices">Home Services</option>
                  <option name="b2bSaaS">B2B SaaS</option>
                  <option name="financialServices">Financial Services</option>
                  <option name="healthWellness">Health & Wellness</option>
                  <option name="healthcareProviders">Healthcare Providers</option>
                  <option name="construction">Construction & Contractors</option>
                  <option name="legalServices">Legal Services</option>
                  <option name="eventPlanning">Event Planning & Services</option>
                  <option name="chiropractors">Chiropractors</option>
                  <option name="dentists">Dentists</option>
                  <option name="cosmeticSurgery">Cosmetic Surgery/Plastic Surgery</option>
                  <option name="personalTrainers">Personal Trainers</option>
                  <option name="mentalHealth">Mental Health & Therapy</option>
                  <option name="propertyManagement">Real Estate Agents (Property Managers)</option>
                  <option name="pestControl">Pest Control</option>
                  <option name="roofingServices">Roofing Services</option>
                  <option name="landscaping">Landscaping & Lawn Care</option>
                  <option name="movingStorage">Moving & Storage Services</option>
                  <option name="tutoringEducation">Tutoring & Education Services</option>
                  <option name="autoRepair">Automotive Repair & Maintenance</option>
                  <option name="homeRemodeling">Home Remodeling & Renovation</option>
                  <option name="travelAgencies">Travel Agencies</option>
                  <option name="taxPreparation">Tax Preparation Services</option>
                  <option name="hvac">HVAC & Air Conditioning Services</option>
                  <option name="electricalServices">Electrical Services</option>
                  <option name="weddingPlanning">Wedding Planning & Services</option>
                  <option name="cateringServices">Catering & Event Services</option>
                  <option name="petGrooming">Pet Grooming & Services</option>
                  <option name="homeInspection">Home Inspection Services</option>
                  <option name="applianceRepair">Appliance Repair Services</option>
                  <option name="interiorDesign">Interior Design Services</option>
                  <option name="fireWaterRestoration">Fire & Water Damage Restoration</option>
                  <option name="securitySystems">Security Systems & Monitoring</option>
                  <option name="cleaningServices">Cleaning Services (Residential & Commercial)</option>
                  <option name="courierDelivery">Courier & Delivery Services</option>
                  <option name="carWashDetailing">Mobile Car Wash & Detailing</option>
                  <option name="customPrinting">Custom Printing Services</option>
                  <option name="poolSpaServices">Pool & Spa Services</option>
                  <option name="computerRepairIT">Computer Repair & IT Support</option>
              </select>

              <br/>

                {view.render_input("Number of Cold Leads", "visitors", "Enter number of cold leads")}
                {view.render_input("Base Conversion Rate (%)", "conversionRate", "Enter conversion rate")}
                {view.render_input("Average Revenue per Lead ($)", "avgRevenue", "Enter average revenue per lead")}
                {view.render_input("Average Lead Age (in days)", "leadAge", "Enter average lead age in days")}

                <h2 className="revenue-calculator-result">Potential Revenue: ${potential_revenue}
                </h2>

                <center>
                  <Button className="ll-button revenue-calculator-result" variant="primary" type="submit"  onClick={view.calculate_revenue}>
                   Calculate Potential Revenue
                  </Button>
                </center>

          </Col>
          <Col md={3}> </Col>
        </Row>

      </React.Fragment>
    );
  }
}

export default RevenueCalculator;

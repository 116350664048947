import {React, Component} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Axios from '../utils/Axios';
import LeadTable from '../components/LeadTable';
import ViewContainer from './ViewContainer';

import play from '../assets/play.png';
import pause from '../assets/pause.png';

class LeadsView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    var client_label;
    if(props.match != null)
    {
      client_label = props.match.params.id;
    }
    else
    {
      client_label = props.client_label;
    }


    this.state =
    {
      app: props.app,
      is_test: props.is_test,
      leads: [],
      leads_per_page: 10,
      client_label: client_label,
      client_job: null,
      clear_jobs: props.clear_jobs
    };
  }

  async componentDidMount()
  {
    await super.componentDidMount();

    const view = this;
    await view.get_client_by_label(view.state.client_label);
  }

  render_view()
  {
    const view = this;

    const { loggedin_user, client, client_job } = view.state;
    if((loggedin_user != null) && (client != null))
    {
      return (
        <Container className="view-container">
            <LeadTable loggedin_user={loggedin_user} fetch_interval={1000} client_label={client.label}/>          
        </Container>
      );
    }
  }
}

export default LeadsView;


import React from 'react';
import axios from 'axios';
import { Table, Button, Modal, Pagination } from 'react-bootstrap';
import TableBase from './TableBase';
import ReferrerModal from '../views/modals/ReferrerModal.js';
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import "./css/Table.css";

class ReferrerTable extends TableBase
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      ...this.state,
      loggedin_user: props.loggedin_user,
      referrers: props.referrers,
      total_referrers: 0,
      show_modal: false
    };

    this.handle_page_change = this.handle_page_change.bind(this);
    this.open_edit_modal = this.open_edit_modal.bind(this);
    this.close_edit_modal = this.close_edit_modal.bind(this);
    this.render_action_buttons = this.render_action_buttons.bind(this);
    this.navigate_to_referrer_view = this.navigate_to_referrer_view.bind(this);
  }

  async fetch_data(on_success)
  {
    const view = this;

    const { loggedin_user, pagination } = this.state;
    const token = Cookies.get("token");
    const url = "/api/referrers/" + `?page=${pagination}`;

    await Axios.Get(url, null, async(response)=>
    {
      if(on_success != null)
      {
        on_success(response);
      }

      const array_data = response.data;
      await view.setState({...view.state, data: array_data.data, data_length: array_data.total_data_length});
    });
  }

  handle_page_change(page)
  {
    this.fetch_referrers(page);
  }

  navigate_to_referrer_view(referrer_id)
  {
    const history = getHistory();
    history.push("/referrer/" + referrer_id)
  }

  render_action_buttons(referrer)
  {
    const view = this;

    return (
      <td>
        <Button className="referrers-table-button" variant="warning" onClick={() => this.open_edit_modal(referrer)}>Edit</Button>
        <Button className="referrers-table-button"variant="danger" onClick={() => this.handle_delete(referrer.id)}>Delete</Button>
      </td>
    );
  }

  render_table_header()
  {
    const view = this;

    return (
      <React.Fragment>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Referral Rate</th>
      </React.Fragment>
    );
  }

  render_table_rows(data)
  {
    const view = this;

    return (
      <React.Fragment>
        <tr key={data.id} className="record-table-row" onClick={() => view.navigate_to_referrer_view(data.id)}>
          <td>{data.first_name}</td>
          <td>{data.last_name}</td>
          <td>{data.email}</td>
          <td>{data.phone}</td>
          <td>{data.referral_rate}%</td>
        </tr>
      </React.Fragment>
    );
  }

  render()
  {
    const view =this;

    const { data, show_modal, selected } = this.state;
    if(data != null)
    {
      return (
        <div>
          <Table striped bordered hover responsive>
            <thead>
              {view.render_table_header()}
            </thead>
            <tbody>
              {data && data.map(data => ( view.render_table_rows(data) ))}
            </tbody>
          </Table>

          {view.render_pagination()}

        </div>
      );
    }
    else
    {
      return <div/>;
    }
  }
}

export default ReferrerTable;

import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import TopNavBar from '../components/TopNavBar';
import ASButton from '../components/ASButton.js';
import CardRow from '../components/CardRow.js';
import ViewContainer from './ViewContainer.js';

import Cookies from 'js-cookie';
import Axios from '../utils/Axios';

import "../App.css"

class HomeView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    if(!props.app.logged_in())
    {
      props.app.navigate_to_root();
    }

    this.app = props.app;
  }

  // componentDidMount()
  // {
  //   await view.get_logged_in_user(async(token, user)=>
  //   {
  //       view.state.app.on_login(token, user);
  //   });
  // }

  logged_in()
  {
    return this.state.app.logged_in();
  }

  render_view()
  {
    const view = this;
    const app = this.props.app;

// <CardRow/>

    return (
        <h1></h1>
    );
  }

}

export default HomeView;

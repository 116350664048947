
import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

class ClientModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            name: null,
            email: null,
            website: null,
            conversion_link: null,
            phone: null,
            opening_hours: null,
            is_submitting: false,
            error: null,
            show_modal: props.show_modal,
            parent_view: props.parent_view,
            onClose: props.on_close,
            is_test: props.is_test
        };


        this.handle_close = this.handle_close.bind(this);
        this.handle_input_change = this.handle_input_change.bind(this);
        this.handle_submit = this.handle_submit.bind(this);
        this.render_form_control = this.render_form_control.bind(this);
    }

    componentDidMount()
    {
      const view = this;
      view.setState({...view.state, show_modal: true});
    }

    handle_input_change(event)
    {
        const { name, value } = event.target;
        this.setState({ ...this.state,  [name]: value });
    }

    async handle_submit(event)
    {
        const view = this;

        event.preventDefault();
        const { name,  email, website, conversion_link, opening_hours, phone, is_test  } = view.state;

        if (!name || !email || !website || !conversion_link || !opening_hours  || !phone)
        {
            await view.setState({...view.state,  error: 'All fields are required.' });
            return;
        }

        await view.setState({ ...view.state, is_submitting: true, error: null });

        try
        {
            const client = {  name, email, website, conversion_link, opening_hours, phone, is_test };
            view.props.parent_view.create_client(client);
            view.handle_close(); // Close the modal after submission
        }
        catch (error)
        {
            view.setState({ ...view.state, error: 'An error occurred. Please try again later.' });
        }
        finally
        {
            view.setState({ ...view.state, is_submitting: false });
        }
    }

    handle_close()
    {
      const view = this;
      view.setState({show_modal: false}, ()=>
      {
          view.state.parent_view.on_close_client_modal();
      })
    }

    render_form_control(label, field_name, value, type)
    {
      const view = this;

      const form_control_type = (type != null) ? type : "text";
      return(
          <Row className="form-row">
            <Form.Group controlId={field_name} style={{width: "90%"}}>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                type={form_control_type}
                name={field_name}
                default_value={value}
                onChange={this.handle_input_change}
              />
            </Form.Group>
        </Row>
      );
    }

    render()
    {
        const view = this;

        const { onClose } = this.props;

        const { name, email, phone, address, website, conversion_link, opening_hours, is_submitting, error, show_modal } = this.state;

        return (
            <Modal show={show_modal} onHide={view.handle_close}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.id ? 'Edit Client' : 'Add New Client'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form onSubmit={this.handle_submit}>
                        {view.render_form_control("Name", "name", name)}
                        {view.render_form_control("Email", "email", email, "email")}
                        {view.render_form_control("Phone", "phone", phone)}
                        {view.render_form_control("Website", "website", website)}
                        {view.render_form_control("Conversion Link", "conversion_link", conversion_link)}
                        {view.render_form_control("Opening Hours", "opening_hours", opening_hours)}

                        <Button variant="primary" type="submit" disabled={is_submitting}>
                        Save Client
                        </Button>
                        <Button className="modal-cancel-button" variant="primary" type="submit" disabled={is_submitting} onClick={view.handle_close}>
                         Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ClientModal;

import React, { Component } from "react";
import {Row, Col} from 'react-bootstrap';

class ProgressBar extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      progress: props.progress
    }
  }

  render()
  {
    const view = this;

    const progress_style=
    {
      width: view.state.progress,
      height: "6px"
    }

    return (
      <React.Fragment>
      <Row>
        <div className="col-12">
          <div className="progress">
            <div className="progress-bar bg-primary" role="progressbar"
              style={progress_style} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </Row>
      </React.Fragment>
    );
  }
};

export default ProgressBar;


import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import ViewContainer from './ViewContainer';
import CSVUpload from '../components/CSVUpload';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class ReferrerView  extends ViewContainer
{
    constructor(props)
    {
        super(props);

        const referrer_id = props.match.params.id;

        this.state =
        {
            app: props.app,
            referrer_id: referrer_id,
            referrer: null
        };

        this.fetch_referrer = this.fetch_referrer.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.handle_submit = this.handle_submit.bind(this);
        this.render_referrer_edit_form = this.render_referrer_edit_form.bind(this);
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(()=>
        {
          view.fetch_referrer();
        });
    }

    async fetch_referrer()
    {
      const view = this;

      const { referrer_id } = this.state;

      const token = Cookies.get("token");
      const url = "/api/referrer/" + referrer_id;

      await Axios.Get(url, null, async(response)=>
      {
          await view.setState({...view.state, referrer: response.data});
      });
    }

    // Handle change in input fields
    async handle_change(event)
    {
      const _this = this;

      const { name, value } = event.target;
      this.setState(prevState => ({
          referrer: {
              ...prevState.referrer,
              [name]: value
          }
      }));
    }

    async handle_submit(event)
    {
      const _this = this;

      event.preventDefault();

      const referrer = _this.state.referrer;

      const post_data=
      {
        referrer_id: _this.state.referrer_id,
         first_name: referrer.first_name,
         last_name: referrer.last_name,
         email: referrer.email,
         phone: referrer.phone,
         referral_rate: referrer.referral_rate
      };

      const url = '/api/referrer';
      const response = await Axios.Put(url, post_data, (response)=>
      {
        console.log(response);
      });
    }

    render_form_control(label, name, type, value)
    {
      return (
        <FormControl
            label={label}
            name={name}
            default_value={value}
            type={type}
            onChange={this.handle_change}/>
      );
    }

    render_referrer_edit_form()
    {
        const view = this;

        const { referrer } = view.state;
        if(referrer != null)
        {
          return (
                  <div className="record-editor">
                    <h2>Referrer</h2>
                    <Form onSubmit={this.handle_submit}>
                        <Row>
                            <Col md={6}>
                                {view.render_form_control("First Name", "first_name", "text", referrer.first_name)}
                            </Col>
                            <Col md={6}>
                                {view.render_form_control("Last Name", "last_name", "text", referrer.last_name)}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                              {view.render_form_control("Email", "email", "email", referrer.email)}
                            </Col>
                            <Col md={6}>
                                  {view.render_form_control("Phone", "phone", "text", referrer.phone)}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                              {view.render_form_control("Referral Rate (%)", "referral_rate", "referral_rate", referrer.referral_rate)}
                            </Col>
                        </Row>

                        <Button variant="primary" className="float-right" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                  </div>

            );
        }
    }

    render_view()
    {
      const view = this;

      const {referrer_id} = view.state;

      return (
          <React.Fragment>
          <Container className="view-container">
            {view.render_referrer_edit_form()}
            <CSVUpload referrer_id={referrer_id}/>
          </Container>
          </ React.Fragment>
      );
    }
}

export default ReferrerView;

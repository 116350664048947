import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import ASButton from '../components/ASButton.js';
import ASComponent from '../components/ASComponent.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
// import Modal from 'react-modal';
import getHistory from '../History';

import "./css/views.css"

const customModalStyles =
{
  content :
  {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class BaseView extends ASComponent
{
  constructor()
  {
    super();

    this.state=
    {
      ...this.state,
      destinations: [],
      modal_is_open: false,
    };

    this.subtitle = React.createRef();
  }

  async get_logged_in_user(success_callback, error_callback, no_token_callback)
  {
    var view = this;

    const token = Cookies.get('token');
    if(token != null)
    {
      if(token != "null")
      {
        await Axios.Get('/api/user_logged_in', token,
        (response)=>
        {
             const loggedin_user= response.data.user;
             view.setState({...view.state, loggedin_user}, ()=>
             {
               // console.log("loggedin_user: ", loggedin_user);
              if(success_callback !== undefined)
              {
                success_callback(loggedin_user);
              }
            });
        },
        (error)=>
        {
            console.log(error);
        });
      }
      else
      if(no_token_callback != null)
      {
        no_token_callback()
      }
    }
    else
    if(no_token_callback != null)
    {
      no_token_callback()
    }
  }

  async get_user_groups(success)
  {
    var view = this;

    Axios.Get('/api/user_groups', null, function(response)
    {
        if(response.data != null)
        {
            const response_data = response.data;
            const found_user_groups = response_data;
            if(found_user_groups != null)
            {
              var user_groups = [];
              for(var i=0; i < found_user_groups.length; ++i)
              {
                  const user_group = found_user_groups[i];
                  user_groups.push(user_group);
              }

              view.setState({...view.state, user_groups}, ()=>
              {
                if(success != null)
                {
                  success(user_groups);
                }
              });
            }
        }
    });
  }

  get_user_group_name(usergroupid, user_groups)
  {
    for(let user_group of user_groups)
    {
      if(usergroupid == user_group.id)
      {
        return user_group.name;
      }
    }
    return null
  }

  user_is_admin(user)
  {
    if(user != null)
    {
      return (user.user_group_name == "Admin") || (user.user_group_name == "SuperAdmin");
    }
    return false;
  }

  navigate_to(url)
  {
    const history = getHistory();
    history.push(url);
  }

  refresh_page()
  {
    const view = this;

    // Force a state update to refresh the view.
    // Dumb, but is works.
    view.setState({...view.state, updated: false}, ()=>{
      view.setState({...view.state, updated: true});
      window.location.reload(true);
    });
  }
}




export default BaseView;

import {React, Component} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ViewContainer from './ViewContainer';

import { GlobalHistory } from '../History';
import getHistory from '../History';


class DemosView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      app: props.app,
    };

    this.navigate_to_demo = this.navigate_to_demo.bind(this);
    this.render_clients_demo_block = this.render_clients_demo_block.bind(this);
    this.render_lead_reactivation_demo_block = this.render_lead_reactivation_demo_block.bind(this);
    this.render_lead_qualification_demo_block = this.render_lead_qualification_demo_block.bind(this);
    this.render_demo_links_block = this.render_demo_links_block.bind(this);
    this.render_demo_button = this.render_demo_button.bind(this);
  }

  render_clients_demo_block(title)
  {
    const view = this;

    return (
      <div className="demo-block">
          <Row>
            <h3>{title}</h3>
          </Row>
          <Row>
            <Col>
              {view.render_demo_button("Demo", "/clients_demo")}
            </Col>
          </Row>
      </div>
    );
  }

  render_lead_reactivation_demo_block(title)
  {
    const view = this;

    return (
      <div className="demo-block">
          <Row>
            <h3>{title}</h3>
          </Row>
          <Row>
            <Col>
              {view.render_demo_button("Demo", "/lead_reactivation_demo/joesplumbing")}
            </Col>
          </Row>
      </div>
    );
  }

  render_lead_qualification_demo_block(title)
  {
    const view = this;

    return (
      <div className="demo-block">
          <Row>
            <h3>{title}</h3>
          </Row>
          <Row>
            <Col>
              {view.render_demo_button("Demo", "/lead_qualification_configuration_demo/axiosrecruitment")}
            </Col>
          </Row>
      </div>
    );
  }

  render_demo_links_block(title, chat_url, sms_url)
  {
    const view = this;

    return (
      <div className="demo-block">
          <Row>
            <h3>{title}</h3>
          </Row>
          <Row>
            <Col>
              {chat_url && view.render_demo_button("Chat", chat_url)}
              {sms_url && view.render_demo_button("SMS", sms_url, true)}
            </Col>
          </Row>
      </div>
    );
  }

  render_demo_button(title, url, float_right)
  {
    const view = this;



    return (
        <Button variant="primary" onClick={() => view.navigate_to_demo(url)}>
          {title}
        </Button>
    );
  }

  navigate_to_demo(demo)
  {
    const history = getHistory();
    history.push(demo)
  }

  redner_de

  render_view()
  {
    const view = this;

    // {view.render_lead_reactivation_demo_block("Lead Reactivation Demo")}
    // {view.render_lead_qualification_demo_block("Lead Qualification Demo")}

    const { loggedin_user, client_id } = view.state;
    if(loggedin_user != null)
    {
      return (
        <Container className="view-container">
          <Row>
            <Col md={12}>


              {view.render_clients_demo_block("Clients Test")}

              <Row style={{margin: "20px 0"}}>
                <hr/>
              </Row>

              {view. render_demo_links_block("Chatbot Demos", "/chatbot_demos")}
              <Row style={{margin: "20px 0"}}>
                <hr/>
              </Row>

              {view. render_demo_links_block("Job Candidate Chat Demo", "/chat_job_candidate", "/sms_job_candidate")}
              {view. render_demo_links_block("Plumbing Chat Demo", "/chat_plumbing", "/sms_plumbing")}
              {view. render_demo_links_block("Real Estate Chat Demo", "/chat_realestate", "/sms_realestate")}
              {view. render_demo_links_block("Needl-IT Chat Demo", "/chat_needl", "/sms_needl")}
              {view. render_demo_links_block("Winsidor Chat Demo", "/chat_winsidor", "/sms_winsidor")}
              {view. render_demo_links_block("Car Dealership Chat Demo", "/chat_downtownauto","/sms_owntownauto")}
              {view. render_demo_links_block("Dentist Chat Demo", "/chat_dentist","/sms_dentist")}

            </Col>
          </Row>

        </Container>
      );
    }
  }
}

export default DemosView;


import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

class ReferrerModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            first_name: "Paul",
            last_name: "Klimau",
            phone: "555 865 6448",
            email: "admin@needl-it.ca",
            website: "needl-it.ca",
            referral_rate:35,
            is_submitting: false,
            error: null,
            show_modal: props.show_modal,
            parent_view: props.parent_view,
            onClose: props.on_close
        };


        this.handle_close = this.handle_close.bind(this);
        this.handle_input_change = this.handle_input_change.bind(this);
        this.handle_submit = this.handle_submit.bind(this);
        this.render_form_control = this.render_form_control.bind(this);
    }

    componentDidMount()
    {
      const view = this;
      view.setState({...view.state, show_modal: true});
    }

    handle_input_change(event)
    {
        const { name, value } = event.target;
        this.setState({ ...this.state,  [name]: value });
    }

    async handle_submit(event)
    {
        const view = this;

        event.preventDefault();
        const { first_name, last_name, phone, email, referral_rate  } = view.state;

        if (!first_name || !last_name || !phone || !email || !referral_rate)
        {
            await view.setState({...view.state,  error: 'All fields are required.' });
            return;
        }

        await view.setState({ ...view.state, is_submitting: true, error: null });

        try
        {
            const referrer = { first_name, last_name, phone, email, referral_rate };
            view.props.parent_view.create_referrer(referrer);
            view.handle_close(); // Close the modal after submission
        }
        catch (error)
        {
            view.setState({ ...view.state, error: 'An error occurred. Please try again later.' });
        }
        finally
        {
            view.setState({ ...view.state, is_submitting: false });
        }
    }

    handle_close()
    {
      const view = this;
      view.setState({show_modal: false}, ()=>
      {
          view.state.parent_view.on_close_referrer_modal();
      })
    }

    render_form_control(label, field_name, value, type)
    {
      const view = this;

      const form_control_type = (type != null) ? type : "text";
      return(
        <Form.Group controlId={field_name}>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type={form_control_type}
            name={field_name}
            value={value}
            onChange={this.handle_input_change}
          />
        </Form.Group>
      );
    }

    render()
    {
        const view = this;

        const { onClose } = this.props;

        const { first_name, last_name, email, phone, address, website, referral_rate, is_submitting, error, show_modal } = this.state;

        return (
            <Modal show={show_modal} onHide={view.handle_close}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.id ? 'Edit Referrer' : 'Add New Referrer'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form onSubmit={this.handle_submit}>
                        {view.render_form_control("First Name", "first_name", first_name)}
                        {view.render_form_control("Last Name", "last_name", last_name)}
                        {view.render_form_control("Email", "email", email, "email")}
                        {view.render_form_control("Phone", "phone", phone)}
                        {view.render_form_control("Website", "website", website)}
                        {view.render_form_control("Referral Rate (%)", "referral_rate", referral_rate)}

                        <Button variant="primary" type="submit" disabled={is_submitting}>
                        Save Referrer
                        </Button>
                        <Button className="modal-cancel-button" variant="primary" type="submit" disabled={is_submitting} onClick={view.handle_close}>
                         Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReferrerModal;

import React, {Component } from "react";
import Axios from "../utils/Axios";
import MessagesView from "./MessagesView";
import Message from "./Message";
import SendMessage from "./SendMessage";
import Constants from "../Constants";
import $ from "jquery";

class ChatBox extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      is_live: props.is_live,
      client_name: props.client_name,
      client_lead_id: props.client_lead_id,
      do_scroll: true,
      conversation_started: false,
      messages: props.messages
    };

    this.on_fetched_messages  = this.on_fetched_messages.bind(this);
    this.on_new_user_message  = this.on_new_user_message.bind(this);
    this.post_new_user_message  = this.post_new_user_message.bind(this);
    this.start_conversation  = this.start_conversation.bind(this);
    this.set_do_scroll  = this.set_do_scroll.bind(this);

    this.messages_view = React.createRef();
  }

  async on_fetched_messages(messages)
  {
    const _this = this;

    const queued_user_message = _this.state.queued_user_message;
    if(queued_user_message != null)
    {
      await _this.setState({..._this.state, queued_user_message: null}, async()=>
      {
        await _this.post_new_user_message(queued_user_message, async()=>
        {
          await _this.setState({..._this.state, do_scroll: false});
        });
      });
    }
  }

  async on_new_user_message(message)
  {
    const _this = this;

    const {is_live, client_lead_id} = _this.state;

    if(is_live && (client_lead_id != null))
    {
      if(!_this.state.conversation_started)
      {
        await _this.start_conversation(async()=>
        {
          const messages_view = _this.messages_view.current;
          if(messages_view != null)
          {
            console.log("messages_view.state: ", messages_view.state);
            const messages = messages_view.state.messages;
            if(messages.length == 1)
            {
                await messages_view.add_fake_message("User", message);
            }

            // We can't post this user message to OpenAI until the run is complete,
            // so queue it here
            await _this.setState({..._this.state, queued_user_message: message});

            // Tell the message list to start fetching messages
            messages_view.start_polling();
          }
        });
      }
      else
      {
        // We can't post this user message to OpenAI until the run is complete,
        // so queue it here
        await _this.setState({..._this.state, do_scroll: true, queued_user_message: message});
      }
    }
  }

  async post_new_user_message(message, on_success)
  {
    const _this = this;

    const {conversation_started, client_lead_id} = _this.state;

    if(conversation_started && (client_lead_id != null))
    {
      const url = '/api/chat/' + client_lead_id;
      const response = await Axios.Post(url, {message}, (response)=>
      {
        // console.log(response);
        _this.set_do_scroll(true);
        if(on_success != null)
        {
          on_success();
        }
      });
    }
  }

  async start_conversation(on_success)
  {
    const _this = this;

    const {conversation_started, client_lead_id} = _this.state;

    if(!conversation_started && (client_lead_id != null))
    {
      const url = "/api/start_chat_conversation/" + client_lead_id;
      await Axios.Get(url, null, async(response2)=>
      {
          console.log("Conversation start: ", response2);
          _this.setState({..._this.state, conversation_started: true}, ()=>
          {
            _this.set_do_scroll(true);
            if(on_success != null)
            {
                on_success();
            }
          });
      });
    }
  }

  set_do_scroll(do_scroll)
  {
    const _this = this;
    _this.setState({do_scroll});
  }

  render()
  {
    const _this = this;

    const {is_live, client_lead_id, read_only, messages} = _this.state;
    if(client_lead_id != null)
    {
       const {messages, do_scroll} = _this.state;
       const chat_padding_top = (_this.props.chat_config != null) ? _this.props.chat_config.chatbox_top_padding : 0;

       return (
         <React.Fragment>
            <main className="chat-box" style={{paddingTop: chat_padding_top}}>

              <MessagesView ref={_this.messages_view}
                            messages={messages}
                            is_live={is_live}
                            client_lead_id={client_lead_id}
                            parent_view={_this}
                            do_scroll={do_scroll}/>

                { is_live &&<div className="send-message-container">
                   <SendMessage parent_view={_this}/>
                  </div>
                }
            </main>
        </React.Fragment>
      );
    }
  }
};

export default ChatBox;

import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
import TopNavBar from '../components/TopNavBar';
import UsersTable from '../components/UsersTable';
import UserGroupsTable from '../components/UserGroupsTable';
import UserPermissionsTable from '../components/UserPermissionsTable';
import ViewContainer from './ViewContainer';
// import UserModal from './modals/UserModal';
import DatePicker from "react-datepicker";

import { GlobalHistory } from '../History';
import getHistory from '../History';

import "react-datepicker/dist/react-datepicker.css";


import RevenueCalculator from '../RevenueCalculator.js';

class UsersView extends ViewContainer
{
    constructor(props)
    {
      super(props);

      this.state =
      {
        ...this.state,
        token: props.token,
        history: props.history,
        app: props.app,
        users: null,
        selected_user: null,
        add_url: "/api/add_user"
      }

      this.get_users = this.get_users.bind(this);
      this.get_user = this.get_user.bind(this);
      this.create_new_user = this.create_new_user.bind(this);

      this.render_user_groups = this.render_user_groups.bind(this);
      this.render_user_permissions = this.render_user_permissions.bind(this);

      this.user_table_ref = React.createRef();
    }

    async componentDidMount()
    {
        var view = this;

        await super.componentDidMount(async ()=>
        {
          await view.get_users();

          const loggedin_user = view.state.loggedin_user;
          if(view.user_is_admin(loggedin_user))
          {
            await view.get_user_permissions();
          }
        });
    }

    async create_new_user(data)
    {
      var view = this;

      Axios.Post("/api/new_user", data, function(response)
      {
        const http_code = parseInt(response.status);
        if(http_code === 200)
        {
          const data = response.data;
        }
      },
      function(error)
      {
        console.log(error);
      });
    }

    async get_users(success)
    {
      var view = this;

      Axios.Get('/api/users', null, function(response)
      {
          if(response.data != null)
          {
              const response_data = response.data;
              const found_users = response_data;
              if(found_users != null)
              {
                var users = [];
                for(var i=0; i < found_users.length; ++i)
                {
                    const user = found_users[i];
                    users.push(user);
                }

                view.setState({...view.state, users}, ()=>
                {
                  if(success != null)
                  {
                    success(users);
                  }
                });
              }
          }
      });
    }

    async get_user_permissions(success)
    {
      var view = this;

      Axios.Get('/api/user_permissions', null, function(response)
      {
          if(response.data != null)
          {
              const response_data = response.data;
              const found_user_permissions = response_data;
              if(found_user_permissions != null)
              {
                var user_permissions = [];
                for(var i=0; i < found_user_permissions.length; ++i)
                {
                    const user_permission = found_user_permissions[i];
                    user_permissions.push(user_permission);
                }

                view.setState({...view.state, user_permissions}, ()=>
                {
                  if(success != null)
                  {
                    success(user_permissions);
                  }

                  view.app.setState({...view.app.state, user_permissions});
                });
              }
          }
      });
    }

    render_users()
    {
      const view = this;

      if((view.state.users != null) /*&& (view.state.user_groups != null)*/)
      {
        return(
          <React.Fragment>
            <Col md={10}>
                 <UsersTable ref={this.user_table_ref} body_array={view.state.users} user_groups={view.state.user_groups} selectable={false}/>
            </Col>
          </React.Fragment>
         )
      }
    }

    render_user_groups()
    {
      const view = this;
      if(view.state.user_groups != null)
      {
        return(
          <Row>
            <Col className="table-col">
              <Row className="table_title_row">
                <h1 className="table_title_row_h1">User Groups</h1>
              </Row>
              <Row>
                <div>
                  <UserPermissionsTable body_array={view.state.user_groups}/>
                </div>
                </Row>
              </Col>
          </Row>
         )
      }
    }

    render_user_permissions()
    {
      const view = this;

      if(view.state.user_permissions != null)
      {
        return(
          <Row>
            <Col className="table-col">
              <Row className="table_title_row">
                <h1 className="table_title_row_h1">User Permissions</h1>
              </Row>
              <Row>
                <div>
                  <UserPermissionsTable body_array={view.state.user_permissions}/>
                </div>
                </Row>
              </Col>
          </Row>
         )
      }
    }

    render_user_modal()
    {
      var view = this;

      /*
      return (
          <UserModal form_url={view.state.add_url} header_text={"Add New User"} no_empty_form_data={false}
                     model={view.state.user} button_label={"Add New User"} on_submit_success={view.on_submit_success} parent={view} editing={false}/>
      );
      */
    }

    // <RevenueCalculator/>

    /*
    <Row className="table_title_row">
      <h1 className="table_title_row_h1">Users</h1>
      {view.render_user_modal()}
    </Row>
    <Row>
      {view.render_users()}
    </Row>
    */

    render_view()
    {
      const view = this;

      if(view.has_permission("View User"))
      {
        return(
          <React.Fragment>
            <Col className="user_view_container">
              <Row>
                <Col className="table-col">


                  <Row className="table_title_row">
                    <h1 className="table_title_row_h1">Users</h1>
                    {view.render_user_modal()}
                  </Row>
                  <Row>
                    {view.render_users()}
                  </Row>

                </Col>
              </Row>

            </Col>

          </React.Fragment>
        );
      }
    }

}


export default UsersView;

import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";

import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
import TopNavBar from '../components/TopNavBar';
import UsersTable from '../components/UsersTable';
import Checkbox from '../components/Checkbox';
import ViewContainer from './ViewContainer';
import UserPermissionsView from './UserPermissionsView';
// import UserModal from './modals/UserModal';
import DatePicker from "react-datepicker";

import PermissionedView from '../views/PermissionedView';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import "react-datepicker/dist/react-datepicker.css";
import "../App.css"

class UserView extends ViewContainer
{
    constructor(props)
    {
      super(props);

      const id = props.match.params.id;
      this.state =
      {
        user_id: id,
        token: props.token,
        history: props.history,
        app: props.app,
        revocation_enabled: false,
        selected_user: null,
        edit_url: "/api/edit_user/" + id
      }

      this.get_user_to_view_or_edit = this.get_user_to_view_or_edit.bind(this);
      this.render_user_row = this.render_user_row.bind(this);
      this.render_user = this.render_user.bind(this);
      this.on_delete = this.on_delete.bind(this);
      this.render_user_permissions = this.render_user_permissions.bind(this);
    }

    async componentDidMount(on_success)
    {
      const view = this;

      super.componentDidMount(async(loggedin_user)=>
      {
        await view.get_user_to_view_or_edit(view.state.user_id, async(user)=>
        {
            const user_groups = view.state.user_groups;
            if((user_groups != null) && (view.state.edit_user != null))
            {
              for(var i=0; i < user_groups.length; ++i)
              {
                const user_group = user_groups[i];
                if(user_group.id == view.state.edit_user.usergroupid)
                {
                  await view.setState({edit_user_usergroup: user_group});
                }
              }
            }

            // view.get_permissions(view.state.edit_user.id);
            // await view.get_effective_user_permissions();

            // const revocation_enabled = await view.loggedin_user_has_permission("Revoke Credential");
            // await view.setState({...view.state, revocation_enabled}, async()=>
            // {
            //   console.log("revocation_enabled: ", view.state.revocation_enabled);
            // });
        });
      });
    }

    async get_user_to_view_or_edit(id, on_success)
    {
      var view = this;

      await Axios.Get('/api/user/' + id, null, async(response)=>
      {
          if(response.data != null)
          {
              const response_data = response.data;
              if(response_data != null)
              {
                 view.setState({...view.state, edit_user:response_data}, async()=>
                 {
                   on_success(view.state.edit_user);
                });
              }
          }
      });
    }

    on_delete()
    {
      const view = this;

      const user = view.state.user;
      const history = view.state.history;
      if(user != null)
      {
        if(view.has_permission("Delete User"))
        {
          Axios.Delete('/delete_user/' + user.id, {}, (response)=>
          {
              if(response.data != null)
              {
                  const response_data = response.data;
                  const found_user = response_data.users;
                  history.push("/users");
              }
          });
        }
      }
    }

    render_user_modal()
    {
      var view = this;

      /*
      return (
          <UserModal form_url={view.state.edit_url} header_text={"Edit User Details"} no_empty_form_data={false}
                     model={view.state.user} button_label={"Edit User"} on_submit_success={view.on_submit_success} parent={view} editing={true}/>
      );
      */
    }

    render_user_row(name, value)
    {
      const view = this;

      return (
        <Row className="user_data_row">
          <Col>
            <div className="user_attribute_label">{name}</div>
            <div className="user_attribute_value">{value}</div>
          </Col>
        </Row>
      );
    }

    render_user()
    {
      const view = this;

      const {edit_user, edit_user_usergroup} = view.state;
      if((edit_user != null) && (edit_user_usergroup != null))
      {
        // Not sure this is needed anymore
        // <ASButton color="dark" on_click={view.on_invite} label={view.state.filter_title} id="table_invitation_button" label="Send Invitation" style={{"float":"right"}}/>

        return (
          <React.Fragment>
            <div className="user_data_container">
              <Row>
                <h2> User Details </h2>
              </Row>
              <Row>
                <Col md={3}>
                {edit_user.firstname && view.render_user_row("First Name", edit_user.firstname)}
                </Col>
                <Col md={3}>
                {edit_user.lastname && view.render_user_row("Last Name", edit_user.lastname)}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                {edit_user.address1 && view.render_user_row("Address 1", edit_user.address1)}
                {edit_user.country && view.render_user_row("Country", edit_user.country)}
                {edit_user.email && view.render_user_row("Email", edit_user.email)}
                </Col>
                <Col md={3}>
                {edit_user.address2 && view.render_user_row("Address 2", edit_user.address2)}
                {edit_user.zip && view.render_user_row("Zip", edit_user.zip)}
                {edit_user.phone && view.render_user_row("Phone", edit_user.phone)}
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  {edit_user_usergroup && view.render_user_row("User Group", edit_user_usergroup.name)}
                </Col>
              </Row>
              {view.render_user_modal()}
              <ASButton color="dark" on_click={view.on_delete} label={view.state.filter_title} id="table_delete_button" label="Delete User"/>
            </div>
            <hr/>
          </React.Fragment>
        );
      }
    }

    render_user_permissions()
    {
      const view = this;

      if(view.has_permission("Edit User"))
      {
        const {edit_user, edit_user_usergroup} = view.state;
        if((edit_user != null) && (edit_user_usergroup != null))
        {
          return (
            <Row>
              <Col className="table-col">
                <h1 className="user_view_container_h1">User</h1>
                {view.render_user()}
                <UserPermissionsView user_id={edit_user.id} usergroup_id={edit_user_usergroup.id} enable_render={true} parent_view={view} />
              </Col>
            </Row>
          );
        }
      }
    }

    revocation_denied_fn()
    {
      console.log("ERROR - Logged in user is not authorized to revoke credentials");
    }

    render_view()
    {
      const view = this;

      if(view.has_permission("View User"))
      {
        const history = view.state.history;

        const {edit_user, edit_user_usergroup} = view.state;
        if((edit_user != null) && (edit_user_usergroup != null))
        {
          return(
            <React.Fragment>
              <Col className="user_view_container">
                <Row>
                  <ASButton color="dark" on_click={ ()=>{history.push("/users");} } label="Users" id="table_delete_button" label="Users"/>
                </Row>
                <br/>

                {view.render_user_permissions()}

              </Col>
            </React.Fragment>
          );
        }
      }
    }
}


export default UserView;


import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import ViewContainer from './ViewContainer';
import AssistantInstructions from '../components/AssistantInstructions';
import LeadReactivation from '../components/LeadReactivation';
import CSVUpload from '../components/CSVUpload';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class LeadReactivationView  extends ViewContainer
{
    constructor(props)
    {
        super(props);

        const client_label = props.match.params.id;

        this.state =
        {
            app: props.app,
            client_label: client_label,
            client: null,
            is_test: props.is_test,
        };

        this.assistant_instructions_loaded = this.assistant_instructions_loaded.bind(this);
        this.fetch_leads = this.fetch_leads.bind(this);

        this.assistant_instructions_ref = React.createRef();
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(async()=>
        {
          await view.get_client_by_label(view.state.client_label, async()=>
          {
            await view.fetch_leads();
          });
        });
    }

    async fetch_leads(on_success)
    {
      const view = this;

      const { loggedin_user, client_label, pagination, pagination_limit } = this.state;

      const url = "/api/get_leads/" + view.state.client_label;

      const post_data =
      {
        client_label: client_label,
        index: 0,
        limit: 10
      };

      await Axios.Post(url, post_data, async(response)=>
      {
        const array_data = response.data;
        await view.setState({...view.state, have_leads: true, leads: response.data});

        if(on_success != null)
        {
          on_success(response.data);
        }
      });
    }

    async assistant_instructions_loaded(assistant_instructions)
    {
      const view = this;

      /*
      const job_desc = view.job_description_ref.current;
      if(job_desc != null)
      {
        if(assistant_instructions.instructions_extra_post != null)
        {
          const job_description = base64.decode(assistant_instructions.instructions_extra_post);
          await view.setState({...view.state, job_description});
          await job_desc.setState({...job_desc.state, job_description});
        }
      }
      */
    }

    has_leads()
    {
      const view = this;
      return view.state.have_leads;
    }

    assistant_is_ready()
    {
      const view = this;

      const assistant_instructions_comp = view.assistant_instructions_ref.current;
      if(assistant_instructions_comp != null)
      {
        return assistant_instructions_comp.state.assistant_ready;
      }
      return false;
    }

    render_view()
    {
      const view = this;

      const {client_label, is_test} = view.state;
      if(client_label != null)
      {
        return (
            <React.Fragment>
            <Container className="view-container">
              <LeadReactivation client_label={client_label} is_test={is_test} parent_view={this} />

              <AssistantInstructions role="Reactivation"
                                     client_label={client_label}
                                     assistant_instructions_loaded={view.assistant_instructions_loaded}
                                     ref={view.assistant_instructions_ref}
                                     is_test={is_test} />
            </Container>
            </ React.Fragment>
        );
      }
    }
}

export default LeadReactivationView;

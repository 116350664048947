
import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import DataSourceUpload from './DataSourceUpload';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import Papa from 'papaparse';
import base64 from 'base-64';
import {Buffer} from 'buffer';

class JobDescription extends DataSourceUpload
{
    constructor(props)
    {
        super(props);

        this.state=
        {
          ...this.state,
          job_description: props.job_description,
          file_change_callback: props.file_change_callback,
          job_description_edit_callback: props.job_description_edit_callback
        }

        this.on_job_description_change = this.on_job_description_change.bind(this);
        this.render_job_description_edit_control = this.render_job_description_edit_control.bind(this);

        this.job_desc_edit_ref = React.createRef();
    }

    handle_file_change(event)
    {
      const view = this;

      const reader = new FileReader()
      reader.onload = async (e) =>
      {
        var text = (e.target.result)
        console.log(text);

        text = view.convert_to_utf8(text);

        await view.setState({ selected_file: event.target.files[0], file_text: text, job_description: text }, ()=>
        {
          view.job_desc_edit_ref.current.value = text;

          console.log("selected_file: ", view.state.selected_file);
          if(view.props.file_change_callback != null)
          {
            view.props.file_change_callback(text);
          }
        });
      };
      reader.readAsText(event.target.files[0])
    }


    async on_job_description_change(event)
    {
      const view = this;

      event.preventDefault();

      const job_description = event.target.value;
      await view.setState({...view.state, job_description}, ()=>
      {
        if(view.state.job_description_edit_callback != null)
        {
          view.state.job_description_edit_callback(job_description);
        }
      });
    }

    render_job_description_edit_control()
    {
      const view = this;

      const { job_description, role } = view.state;
      if(job_description != null)
      {
        return (
            <Form.Group className="mb-3" controlId="job-description-textarea" style={{marginTop: "30px"}}>
              <Form.Label>Job Description</Form.Label>
              <Form.Control className="job-description-textarea"
                            as="textarea"
                            id="job-description"
                            name="job-description"
                            rows={3}
                            defaultValue={job_description}
                            ref={view.job_desc_edit_ref}
                            onChange={this.on_job_description_change}/>
            </Form.Group>
        );
      }
    }

    render()
    {
      const view = this;

      const {is_test} = view.state;

      return (
          <Container className="data_source-upload-container">
              <h2>Upload Job Description</h2>
              <Form onSubmit={this.handle_upload}>
                  <Form.Group>
                      <Form.Label>Select Job Description File</Form.Label>
                      <Form.Control
                          type="file"
                          accept="*.*"
                          onChange={view.handle_file_change}
                      />
                  </Form.Group>

                  {view.render_job_description_edit_control()}
              </Form>
          </Container>
      );
    }
}

export default JobDescription;

import React from "react";
import GoogleSignin from "../img/btn_google_signin_dark_pressed_web.png";

import wordmark from '../assets/wordmark.png';

const NavBar = () =>
{
// <h1>Cold Lead Reactivation Demo</h1>

  return (
    <nav className="nav-bar">
      <img src={wordmark} className="chat-logo" style={{maxHeight: "40px"}}/>
    </nav>
  );
};

export default NavBar;

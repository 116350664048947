import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';

import wordmark from '../assets/menu-wordmark.png';

class SignupView extends Component
{
    constructor(props)
    {
      super(props);

      this.state = {
        email: "mail@alexanderhoughton.com",
        password: "",
        confirm_password: "",
        app: props.app
      }

      this.on_signup = this.on_signup.bind(this);
      this.on_email_change = this.on_email_change.bind(this);
      this.on_password_change = this.on_password_change.bind(this);
      this.on_confirm_password_change = this.on_confirm_password_change.bind(this);
      this.validate_form = this.validate_form.bind(this);
      this.render_signup_form = this.render_signup_form.bind(this);
    }

    on_signup(e)
    {
      e.preventDefault();
      e.stopPropagation();

      var view = this;

      // TODO: Email validation here

      if((this.state.email.length > 0) && (this.state.password.length > 0) && (this.state.password == this.state.confirm_password))
      {
        const data =
        {
          signup_data:
          {
            email: this.state.email,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            organization: "Aries Auth"
          }
        }

        Axios.Post('/api/signup/', data, function(response)
        {
            const token = response.data.token;
            if(view.props.on_signup != null)
            {
                view.props.on_signup(token);
            }
        });
      }
    }

    on_email_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, email: e.target.value});
    }

    on_password_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, password: e.target.value});
    }

    on_confirm_password_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, confirm_password: e.target.value});
    }

    validate_form()
    {
        return (this.state.email.length > 0) && (this.state.password.length > 0);
    }

    render_signup_form()
    {
      const view = this;

      return (
          <Row>
            <Col xs={1} sm={1} md={3}></Col>
            <Col xs={12} sm={10} md={6}>
            <img src={wordmark} className="signin-logo"/>
            <Form onSubmit={view.on_signup} className={"login-form"}>
              <FormGroup controlId="email" >
                <Form.Label>Email</Form.Label>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.on_email_change}
                />
              </FormGroup>
              <FormGroup controlId="password" >
                <Form.Label>Password</Form.Label>
                <FormControl
                  value={this.state.password}
                  onChange={this.on_password_change}
                  type="password"
                />
              </FormGroup>
              <FormGroup controlId="confirm_password" >
                <Form.Label>Confirm Password</Form.Label>
                <FormControl
                  value={this.state.confirm_password}
                  onChange={this.on_confirm_password_change}
                  type="password"
                />
              </FormGroup>
              <Button block  disabled={!this.validate_form()} type="submit" className="as_button">
                Signup
              </Button>
            </Form>
            </Col>
            <Col xs={1} sm={1} md={3}></Col>
          </Row>
      );
    }

    render()
    {
      const view = this;

      return(
        <React.Fragment>

          <Container className="absolute-center is-responsive" style={{marginTop: "20%"}}>
            <Row>
            <Col lg={12} md={12} sm={12} className="main-col">
               <Row className="main-row">

               <Col lg={12} md={12} sm={12} className="coming-soon-col">
                  {this.render_signup_form()}
                </Col>

               </Row>
            </Col>
            </Row>
          </Container>

        </React.Fragment>
      );
    }

}


export default SignupView;


import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import ASComponent from './ASComponent';
import FormControl from '../views/FormControl'; // Importing the FormControl class
import base64 from 'base-64';
import $ from "jquery";

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';

import '../App.css';

class AssistantInstructions extends ASComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            app: props.app,
            client_label: props.client_label,
            role: props.role,
            assistant_instructions_id: props.assistant_instructions_id,
            assistant_instructions: null,
            created: false,
            allow_instructions_editing: true, // props.allow_instructions_editing,
            is_text: props.is_test,
            instructions_loaded_callback: props.assistant_instructions_loaded
        };

        this.fetch_or_create_assistant_instructions = this.fetch_or_create_assistant_instructions.bind(this);
        this.fetch_assistant_instructions = this.fetch_assistant_instructions.bind(this);
        this.create_or_update_assistant = this.create_or_update_assistant.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.handle_send = this.handle_send.bind(this);
        this.render_instructions_edit_control = this.render_instructions_edit_control.bind(this);
        this.render_assistant_instructions_edit_form = this.render_assistant_instructions_edit_form.bind(this);
    }

    async componentDidMount()
    {
        const view = this;

        super.componentDidMount();

        await view.get_client_by_label(view.state.client_label, ()=>
        {
          const {assistant_instructions_id, client_label} = view.state;
          if(client_label != null)
          {
            view.fetch_or_create_assistant_instructions()
          }
        });
    }

    async fetch_or_create_assistant_instructions()
    {
      const view = this;

      const {client, role, client_label} = view.state;

      view.fetch_assistant_instructions(async (assistant_instructions)=>
      {
        if(assistant_instructions == null)
        {
          if(role == "Reactivation")
          {
            assistant_instructions =
            {
              client_id: client.id,
              niche: "Plumbing",
              service: "pipe repairs",
              sales_bot_name: "Bob",
              niche_question: "What problem are you having with your pipes?",
              first_question: "Hi, it’s {SALES_BOT_NAME} from {CLIENT_NAME} here. Is this the same {LEAD_NAME} that got in touch with us recently?",
              schedule_callback_question:"Would you like to schedule a callback?",
              conversion_link: "https://" + client.label + ".com/bookings"
            };
          }
          else
          {
            assistant_instructions =
            {
              client_id: client.id,
              niche: "Recruitment",
              service: "Help with filling job vacancies",
              sales_bot_name: "Clarissa",
              niche_question: "Would you be interested in a new position for {JOB_VACANCY_TITLE}?",
              first_question: "Hi, it’s {SALES_BOT_NAME} from {CLIENT_NAME} here. Is this the same {LEAD_NAME} that got in touch with us recently?",
              schedule_callback_question:"Would you like to schedule a callback?",
              conversion_link: "https://" + client.label + ".com/bookings"
            };
          }

          await view.setState({...view.state, assistant_instructions}, async()=>
          {
            var url;
            if(role == "Reactivation")
            {
              url = "/api/default_assistant_instructions/";
            }
            else
            {
              url = "/api/default_qualification_assistant_instructions/";
            }

            await Axios.Get(url, null, async(response)=>
            {
              if(response.data != null)
              {
                const default_instructions = base64.decode(response.data);
                const assistant_instructions = view.state.assistant_instructions;
                assistant_instructions.instructions = default_instructions;
                await view.setState({...view.state, default_instructions,
                                                    assistant_instructions,
                                                    created: true},
                ()=>
                {
                    if(view.state.instructions_loaded_callback != null)
                    {
                      view.state.instructions_loaded_callback(assistant_instructions);
                    }
                });
              }
            });
          });
        }
      });
    }

    async fetch_assistant_instructions(on_success)
    {
      const view = this;

      const { client_label, client, role } = this.state;

      const url = "/api/assistant_instructions/" + client.id;
      await Axios.Get(url, null, async(response)=>
      {
        var assistant_instructions = response.data;
        if(assistant_instructions != null)
        {
          assistant_instructions.instructions = base64.decode(assistant_instructions.instructions);
          await view.setState({...view.state, assistant_instructions, created: false, assistant_ready: true}, ()=>
          {
            if(view.state.instructions_loaded_callback != null)
            {
                view.state.instructions_loaded_callback(assistant_instructions);
            }
            on_success(assistant_instructions);
          });
        }
        else
        {
            on_success(null);
        }
      });
    }

    // Handle change in input fields
    async handle_change(event)
    {
      const _this = this;

      const { name, value } = event.target;
      var assistant_instructions = _this.state.assistant_instructions;
      assistant_instructions[name] = value;

      this.setState({assistant_instructions});
    }

    async create_or_update_assistant(on_success)
    {
      const _this = this;

      const {assistant_instructions, client_label, client, created, instructions_extra_post} = _this.state;

      var encoded_instructions_extra_post = null;
      if(instructions_extra_post != null)
      {
        encoded_instructions_extra_post = base64.encode(instructions_extra_post);
      }

      const assistant_instructions_post_data=
      {
        id: _this.state.id,
        client_id: assistant_instructions.client_id,
        assistant_id: assistant_instructions.assistant_id,
        niche: assistant_instructions.niche,
        service: assistant_instructions.service,
        sales_bot_name: assistant_instructions.sales_bot_name,
        first_question: assistant_instructions.first_question,
        niche_question: assistant_instructions.niche_question,
        schedule_callback_question: assistant_instructions.schedule_callback_question,
        conversion_link: assistant_instructions.conversion_link,
        instructions: base64.encode(assistant_instructions.instructions),
        instructions_extra_post: encoded_instructions_extra_post
      };

      const post_data=
      {
        client_id: client.id,
        role: "Reactivation",
        assistant_instructions: assistant_instructions_post_data
      };

      if(created)
      {
        const url = '/api/create_assistant/';
        const response = await Axios.Post(url, post_data, async(response)=>
        {
          const assistant_ready = (response.status==200) ? true : false;
          await _this.setState({..._this.state, created: false, assistant_ready},
          ()=>
          {
              if(on_success != null)
              {
                on_success();
              }
          });
        });
      }
      else
      {
        const url = '/api/edit_assistant/';
        const response = await Axios.Put(url, post_data, async(response)=>
        {
          const assistant_ready = (response.status==200) ? true : false;
          await _this.setState({..._this.state, created: false, assistant_ready},
          ()=>
          {
              if(on_success != null)
              {
                on_success();
              }
          });
        });
      }
    }

    async handle_send(event)
    {
      const _this = this;

      event.preventDefault();
      _this.create_or_update_assistant();
    }

    render_form_control(label, name, type, value)
    {
      return (
        <FormControl
            label={label}
            name={name}
            default_value={value}
            type={type}
            onChange={this.handle_change}/>
      );
    }

    render_instructions_edit_control()
    {
      const view = this;

        const { assistant_instructions, role } = view.state;

      return (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{marginTop: "30px"}}>
            <Form.Label>Instructions</Form.Label>
            <Form.Control className="instructions-textarea"
                          as="textarea"
                          id="instructions"
                          name="instructions"
                          rows={3}
                          defaultValue={assistant_instructions.instructions}
                          onChange={this.handle_change}/>
          </Form.Group>
      );
    }

    render_assistant_instructions_edit_form()
    {
        const view = this;

        const { assistant_instructions, allow_instructions_editing, role } = view.state;
        if(assistant_instructions != null)
        {
          return (
                  <div className="record-editor">
                    <h2>{role} Assistant Instructions</h2>

                    <Form onSubmit={view.handle_send}>
                        <Row className="form-row">
                          <Col md={6}>
                            <p><strong>These values are suggestions only. <br/>Make sure the conversion link is accurate.<br/></strong></p>
                          </Col>
                          <Col md={6}>
                            <Button variant="primary" className="float-right" type="submit">
                                Save Changes
                            </Button>
                          </Col>
                        </Row>
                        <Row className="form-row">
                            <Col md={6}>
                                {view.render_form_control("Niche", "niche", "text", assistant_instructions.niche)}
                            </Col>
                            <Col md={6}>
                                {view.render_form_control("Service", "service", "text", assistant_instructions.service)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={6}>
                                {view.render_form_control("Sales Bot Name", "sales_bot_name", "text", assistant_instructions.sales_bot_name)}
                            </Col>
                        </Row>
                        <Row className="form-row">
                            <Col md={12}>
                                {view.render_form_control("First message", "first_question", "text", assistant_instructions.first_question)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={12}>
                                {view.render_form_control("Niche Question", "niche_question", "text", assistant_instructions.niche_question)}
                            </Col>
                        </Row>
                        <Row className="form-row">
                            <Col md={12}>
                                {view.render_form_control("Schedule Callback Question", "schedule_callback_question", "text", assistant_instructions.schedule_callback_question)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={12}>
                                {view.render_form_control("Conversion Link", "conversion_link", "text", assistant_instructions.conversion_link)}
                            </Col>
                            <Col md={12}>

                            {allow_instructions_editing && view.render_instructions_edit_control()}
                            </Col>
                        </Row>

                        <Button variant="primary" className="float-right" type="submit">
                            Save Changes
                        </Button>
                        <br/>
                        <br/>
                        <br/>
                    </Form>
                  </div>

            );
        }
    }

    render()
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        return (
            <React.Fragment>

              {view.render_assistant_instructions_edit_form()}
            </ React.Fragment>
        );
      }
    }
}

export default AssistantInstructions;

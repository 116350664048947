
import React, { Component } from "react";
import ASComponent from './ASComponent';
import {Row, Col} from 'react-bootstrap';

class Checkbox extends ASComponent
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      id: props.id,
      name: props.name,
      checked: props.checked,
      label: props.label,
      on_change: props.on_change
    }

    // console.log(props.name);
    // console.log("checked: ", props.checked);
  }

  render()
  {
    const view = this;

    const {id, name, label, checked, on_change} = view.state;

    return (
      <Col md={3} key={id} >
        <div className="custom-control ">
         <input type="checkbox"
                className="custom-control-input"
                id={id}
                name={name}
                label={name}
                onChange={on_change}
                checked={checked}/>
         <label className="custom-control-label" htmlFor={id}>{label}</label>
       </div>
      </Col>
    );
  }
}

Checkbox.defaultProps=
{
  title: ""
}

export default Checkbox;

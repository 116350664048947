import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import ASComponent from '../components/ASComponent';
import SideBar from '../components/SideBar';
import TopNavBar from '../components/TopNavBar';
import PermissionedView from './PermissionedView';

import BaseView from './BaseView';
import Axios from '../utils/Axios';

class ViewContainer extends PermissionedView
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      ...this.state,
      search_results: null
    }

    this.render_sidebar = this.render_sidebar.bind(this);

    if(props.app != null)
    {
      if(!props.app.logged_in())
      {
        props.app.navigate_to_root();
      }

      this.app = props.app;
    }
  }

  async componentDidMount(loggedin_user_on_success)
  {
    var view = this;

    await super.componentDidMount(async()=>
    {
      if(loggedin_user_on_success != null)
      {
        loggedin_user_on_success(view.state.loggedin_user);
      }
    });
  }

  render_sidebar()
  {
    const view = this;
    return <SideBar current_view={view}/>;
  }

  render_top_navbar()
  {
    const view = this;
    const app = this.props.app;

    return <TopNavBar app={app} navbar_className="loggedin-navbar"/>;
  }

  render()
  {
    const view = this;
    const app = this.props.app;

    //   max-height: 500px;

    // <UserPermissions ref={view.user_permissions} user_id={loggedin_user.id} enable_render={false}/>

    // <TopNavBar app={app} navbar_className="loggedin-navbar"/>
    const loggedin_user = view.state.loggedin_user;
    // if(view.state.loggedin_user != null)
    {
      return (
        <React.Fragment>
          <div id="wrapper">

            {view.render_sidebar()}
            <div className="d-flex flex-column" id="content-wrapper">
                {view.render_top_navbar()}
                <Row className="main-row full-height full-width">
                  {view.render_view()}
                </Row>
            </div>
          </div>
          </React.Fragment>
        );
    }
    return <div/>
  }

}

export default ViewContainer;

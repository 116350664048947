import React from 'react';
import { Table, Button, Pagination, Row, Col, Container } from 'react-bootstrap';

import pagination_start from '../assets/pagination-start.png';
import pagination_back from '../assets/pagination-back.png';
import pagination_forward from '../assets/pagination-forward.png';
import pagination_end from '../assets/pagination-end.png';

class TableBase extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      client_id: props.client_id,
      data: props.data,
      total_leads: 0,
      current_page: 0,
      leads_per_page: 10,
      show_modal: false,
      selected: null,
      pagination: 0,
      pagination_limit: 10
    };

    this.fetch_data = this.fetch_data.bind(this);
    this.open_edit_modal = this.open_edit_modal.bind(this);
    this.close_edit_modal = this.close_edit_modal.bind(this);
    this.render_action_buttons = this.render_action_buttons.bind(this);

    this.on_pagination_start = this.on_pagination_start.bind(this);
    this.on_pagination_back = this.on_pagination_back.bind(this);
    this.on_pagination_forward = this.on_pagination_forward.bind(this);
    this.on_pagination_end = this.on_pagination_end.bind(this);

    this.render_table_header = this.render_table_header.bind(this);
    this.render_table_rows = this.render_table_rows.bind(this);

    this.render_pagination = this.render_pagination.bind(this);
    this.render_modal = this.render_modal.bind(this);
  }

  async componentDidMount()
  {
    const view = this;

    if(view.state.fetch_interval != null)
    {
      await view.fetch_data();
      view.intervalId = setInterval(async () =>
      {
        await view.fetch_data((response)=>
        {
          // console.log("response: ", response.data);
        });
      }, 1000);
    }
    else
    {
      await view.fetch_data((response)=>
      {
      //   console.log("response: ", response.data);
      });
    }
  }

  async componentWillUnmount()
  {
    const _this = this;
    clearInterval(_this.intervalId);
  }

  async fetch_data(on_success)
  {
    const view = this;

  }

  open_edit_modal(data)
  {
    this.setState({ show_modal: true, selected: data });
  }

  close_edit_modal()
  {
    this.setState({ show_modal: false, selected: null });
  }

  async on_pagination_start()
  {
    const view = this;

    var {pagination, pagination_limit, data} = view.state;
    if(data != null)
    {
      pagination = 0;
      await view.setState({pagination}, ()=>
      {
        view.fetch_data();
      });
    }
  }

  async on_pagination_back()
  {
    const view = this;

    var {pagination, pagination_limit, data} = view.state;
    if(data != null)
    {
      pagination -= pagination_limit;
      if(pagination < 0)
      {
        pagination = 0;
      }

      await view.setState({pagination}, ()=>
      {
        view.fetch_data();
      });
    }
  }

  async on_pagination_forward()
  {
    const view = this;

    var {pagination, pagination_limit, data, data_length} = view.state;
    if(data != null)
    {
      if(pagination + pagination_limit >= data_length)
      {
        pagination = data_length - (data_length % pagination_limit);
      }
      else
      {
        pagination += pagination_limit;
      }

      await view.setState({pagination}, ()=>
      {
        view.fetch_data();
      });
    }
  }

  async on_pagination_end()
  {
    const view = this;

    var {pagination, pagination_limit, data, data_length} = view.state;
    if(data != null)
    {
      pagination = data_length - (data_length % pagination_limit);

      await view.setState({pagination}, ()=>
      {
        view.fetch_data();
      });
    }
  }

  render_table_header()
  {
  }

  render_table_rows(data)
  {
  }

  render_pagination()
  {
    const view = this;

    return (
      <Row className="pagination_group">
        <span>
          <img src={pagination_start} className="pagination_start" onClick={view.on_pagination_start}/>
          <img src={pagination_back} className="pagination_back" onClick={view.on_pagination_back}/>
        </span>
        <span>
          <img src={pagination_forward} className="pagination_forward" onClick={view.on_pagination_forward}/>
          <img src={pagination_end} className="pagination_end" onClick={view.on_pagination_end}/>
        </span>
      </Row>
    );
  }

  render_modal(show_modal, selected)
  {
  }

  render_action_buttons(lead)
  {
    const view = this;

    return (
      <td>
        <Button className="data-table-button" variant="warning" onClick={() => this.open_edit_modal(lead)}>Edit</Button>
        <Button className="data-table-button"variant="danger" onClick={() => this.handle_delete(lead.id)}>Delete</Button>
      </td>
    );
  }

  render()
  {
    const view =this;

    const { data, show_modal, selected } = this.state;
    if(data != null)
    {
      return (

            <div className="lead-table">

              <Row className="my-4">
                <Col>
                <h1 className="leads-view-table-title">Leads</h1>
              </Col>
              </Row>

              <Row className="my-4">
                <Col>
                  <Table striped bordered hover responsive>
                    <thead>
                      {view.render_table_header()}
                    </thead>
                    <tbody>
                      {data && data.map(data => ( view.render_table_rows(data) ))}
                    </tbody>
                  </Table>

                  {view.render_pagination()}
                  {view.render_modal(show_modal, selected)}
                </Col>
              </Row>
            </div>
        
      );
    }
    else
    {
      return <div/>;
    }
  }
}

export default TableBase;

import React, { Component } from "react";
import {Row, Col, Navbar, Nav, Form, FormControl, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../App.css';
import wordmark from '../assets/wordmark.png';

import dashboard from '../assets/speedometer.png';
import users from '../assets/group.png';
import databases from '../assets/database.png';
import partners from '../assets/partner.png';
import settings from '../assets/settings.png';

import connections from '../assets/connection.png';
import credentials from '../assets/id-card.png';
import credential from '../assets/credential.png';
import organization from '../assets/organization.png';

class SideBar extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      app: props.app,
      current_view: props.current_view
    }

    this.on_click_test_links_menu = this.on_click_test_links_menu.bind(this);
  }

  render_link(url, title, permission, icon, icon_style)
  {
    // <a href={url} className="nav-link">
    const view = this;
    const link_icon_style = (icon_style != null) ? icon_style : {};

    const current_view = view.state.current_view;
    const has_permission = (permission!=null) ? current_view.loggedin_user_has_permission(permission) : true;

    if(has_permission)
    {
      return (
        <Link to={url} className="nav-link">
          <span className="link-title">{title}</span>
        </Link>
      );
    }
  }

  on_click_test_links_menu(e)
  {
    e.preventDefault();

    const view = this;
    view.setState({...view.state, render_test_links: true});
  }

  logout(event)
  {
    event.preventDefault();
    this.state.app.logout();
  }

  render()
  {
    const view = this;

    // const user_is_super_admin = view.state.current_view.loggedin_user_is_super_admin();
    // const user_is_admin = (view.state.current_view.loggedin_user_is_admin()); //  || user_is_super_admin;

    const current_view = view.state.current_view;
    if(current_view != null)
    {
      // <li className="nav-item">
      //   {view.render_link("/dashboard", "Dashboard", dashboard)}
      // </li>

      return (
        <React.Fragment>

        <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
          <div className="container-fluid d-flex flex-column p-0 sidebar-links">
              <li className="nav-item nav-category">
                <h4 className="nav-category-header">
                  <a href="/"><img src={wordmark}/></a>
                </h4>
              </li>
            <div className="side-menu">

              <li className="nav-item">
                {view.render_link("/clients", "Clients", "View Client", dashboard)}
              </li>

              <li className="nav-item">
                {view.render_link("/users", "Users", "View User", users)}
              </li>

              <li className="nav-item">
                {view.render_link("/test", "Test", "View Demo", dashboard)}
              </li>

              <li className="nav-item">
                {view.render_link("/referrers", "Referrers", "View Referrer", dashboard)}
              </li>

            </div>
          </div>
        </nav>

        </React.Fragment>
      );
    }
    return <div/>
  }
};


export default SideBar;

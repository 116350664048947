
import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import ASComponent from './ASComponent';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import Papa from 'papaparse';
import base64 from 'base-64';
import {Buffer} from 'buffer';

class DataSourceUpload extends ASComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
          file_change: props.file_change_callback,
          client_label: props.client_label,
          selected_file: null,
          is_test: props.is_test
        };

        this.handle_file_change = this.handle_file_change.bind(this);
        this.handle_upload = this.handle_upload.bind(this);
        this.handle_toggle_change = this.handle_toggle_change.bind(this);
        this.render_checkbox = this.render_checkbox.bind(this);
    }

    handle_file_change(event)
    {
      const view = this;

      const reader = new FileReader()
      reader.onload = async (e) =>
      {
        var text = (e.target.result)
        console.log(text);

        text = view.convert_to_utf8(text);

        // const latin1_buffer = Buffer.transcode(Buffer.from(text));
        // const latin1_data = latin1_buffer.toString("latin1");

        view.setState({ selected_file: event.target.files[0], file_text: text }, ()=>
        {
          console.log("selected_file: ", view.state.selected_file);
          if(view.props.file_change_callback != null)
          {
            view.props.file_change_callback(text);
          }
        });
      };
      reader.readAsText(event.target.files[0])
    }

    handle_toggle_change(e)
    {
      const view = this;

      view.setState({ is_test: e.target.checked });
    }

    async handle_upload(event)
    {
      const view = this;

      event.preventDefault();
      const { selected_file, is_test, client_label, file_text } = this.state;

      if(!selected_file)
      {
          alert("Please select a file to upload.");
          return;
      }

      if((selected_file != null) /*&& (client_label != null)*/)
      {
        const upload_data=
        {
          filename: selected_file.name,
          file_data: file_text,
          client_label: client_label,
          is_test: is_test
        };

        await Axios.Post('/api/data_source_upload/' + client_label, upload_data, async(response)=>
        {
            console.log("data_source_upload response: ", response);
            if(view.state.uploaded_callback != null)
            {
              view.state.uploaded_callback(selected_file.name, file_text);
            }
        }, null, true);
      }
    }

    render_checkbox(id, name, label, checked)
    {
      const view = this;

      if(!view.state.force_is_test)
      {
        return (
          <Col md={3}>
            <div className="custom-control ">
             <input type="checkbox"
                    className="custom-control-input"
                    id={id}
                    name={name}
                    label={name}
                    onChange={view.handle_toggle_change}
                    checked={checked}/>
             <label className="custom-control-label" htmlFor={id}>{label}</label>
           </div>
          </Col>
        );
      }
    }

// <Checkbox name="Devvelopment" label="For Development" on_change={view.handle_toggle_change}/>

    render()
    {
      const view = this;

      const {is_test} = view.state;

      // <Form.Group>
      //   {view.render_checkbox("sfgsf", "Development", "For Development", is_test)}
      // </Form.Group>

      return (
          <Container className="data_source-upload-container">
              <h2>Upload Job Description</h2>
              <Form onSubmit={this.handle_upload}>
                  <Form.Group>
                      <Form.Label>Select Job Description File</Form.Label>
                      <Form.Control
                          type="file"
                          accept="*.*"
                          onChange={this.handle_file_change}
                      />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                      Upload
                  </Button>
              </Form>
          </Container>
      );
    }
}

export default DataSourceUpload;

import React, {Component} from 'react';
import NavBar from "../components/NavBar";
import ChatBox from "../components/ChatBox";
import Axios from '../utils/Axios';
import ViewContainer from './ViewContainer';

class ChatbotView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    // const loggedin_user = props.app.state.loggedin_user;

    // var client_lead_id = (props.client_lead_id != null) ? props.client_lead_id : props.match.params.id;

    this.state=
    {
      is_live: true,

      app: props.app,
      // loggedin_user_id: props.loggedin_user_id,
      client_lead_id: props.client_lead_id,
      client_name: props.client_name,
    };

    // this.get_client_lead_from_loggedin_user = this.get_client_lead_from_loggedin_user.bind(this);
  }

  // async componentDidMount()
  // {
  //   const _this = this;
  //
  //   super.componentDidMount(async(loggedin_user)=>
  //   {
  //     _this.get_client_lead_from_loggedin_user(loggedin_user);
  //   });
  // }
  //
  // async get_client_lead_from_loggedin_user(loggedin_user)
  // {
  //   const _this = this;
  //
  //   if(loggedin_user != null)
  //   {
  //     await _this.setState({..._this.state, loggedin_user_id: loggedin_user.id}, async()=>
  //     {
  //       const {loggedin_user_id, client_name} = _this.state;
  //       if(loggedin_user_id != null)
  //       {
  //         const url = "/api/get_client_lead/" + client_name + "_" + loggedin_user_id;
  //         await Axios.Get(url, null, async(response)=>
  //         {
  //           const client_lead = response.data;
  //           await _this.setState({..._this.state, client_lead: client_lead});
  //         });
  //       }
  //     });
  //   }
  // }

  render()
  {
    const _this = this;

    const {is_live, client_name, client_lead_id, loggedin_user_id} = _this.state;
    const chatbox_height = (_this.props.chat_config != null) ? _this.props.chat_config.chatbox_height : "100%";

    if(client_lead_id != null)
    {
      return (
        <React.Fragment>
            <NavBar />

            <div style={{height: chatbox_height}}>
              <ChatBox is_live={is_live}
                       client_name={client_name}
                       client_lead_id={client_lead_id}
                       chat_config={_this.props.chat_config}/>
            </div>
      </React.Fragment>
      );
    }
  }

};

export default ChatbotView;

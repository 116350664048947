import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import getHistory from '../History';

// import ComingSoonView from './ComingSoonView';
import LoggedOutView from './LoggedOutView';
import HomeView from './HomeView';
// import QRCodeLoginView from './QRCodeLoginView';
import LoggedOutNavBar from '../components/LoggedOutNavBar';
import TopNavBar from '../components/TopNavBar';
import Countdown from "../components/Countdown";
import LoginView from './LoginView';

import wordmark from '../assets/wordmark.png';

import Cookies from 'js-cookie';
import { Route, Switch } from 'react-router-dom';
// import santorini_1 from '../assets/santorini_1.jpg';

class MainView extends Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      token: null,
      app: props.app
    }

    this.render_loggedout_view = this.render_loggedout_view.bind(this);
  }

  logged_in()
  {
    return (this.state.token != null) ? true : false;
  }

  validate_search_form()
  {
      return true;
  }

  render_login_view()
  {
    // return <QRCodeLoginView app={this} logo={wordmark} on_login={this.on_login} app={this.state.app}/>
  }

  render_nav_bar()
  {
    const view = this;

    if(view.logged_in())
    {
      const token = view.get_token();
      if((token != null) && (token.length > 0))
      {
        return (  <TopNavBar app={view}/> );
      }
    }
    // return ( <LoggedOutNavBar app={this}/> );
  }

  render_coming_soon()
  {
    return (
        <Countdown />
    );
  }

  render_home_view(app)
  {
    return <HomeView app={app}/>
  }

  render_loggedout_view()
  {
    const view = this;
    const app = view.state.app;

    return <LoggedOutView app={app}/>
  }

  render()
  {
    const view = this;

    const history = getHistory();

    // console.log("Rendering MainView...");

    // return <h1>MainView</h1>

    const app = view.props.app;
    if(app.logged_in())
    {
      return view.render_home_view(app);
    }
    return view.render_loggedout_view(app);
  }
}

export default MainView;

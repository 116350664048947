
import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import Papa from 'papaparse';
import base64 from 'base-64';

class CSVUpload extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
          uploaded_callback: props.uploaded_callback,
          client_label: props.client_label,
          selected_file: null,
          is_test: props.is_test
        };

        this.read_csv = this.read_csv.bind(this);
        this.update_csv_data = this.update_csv_data.bind(this);

        this.handle_file_change = this.handle_file_change.bind(this);
        this.handle_send = this.handle_send.bind(this);
        this.handle_toggle_change = this.handle_toggle_change.bind(this);

        this.render_checkbox = this.render_checkbox.bind(this);
    }

    read_csv()
    {
      const view = this;

      Papa.parse(view.state.selected_file,
      {
        complete: view.update_csv_data,
        header: true
      });
   };

   async update_csv_data(result)
   {
      const view = this;

      var data = result.data;
      const encoded = base64.encode(JSON.stringify(data));
      await view.setState({ csv_data: encoded });
   }

    handle_file_change(event)
    {
      const view = this;

      this.setState({ selected_file: event.target.files[0] }, ()=>
      {
        console.log("selected_file: ", view.state.selected_file);
        view.read_csv();
      });
    }

    handle_toggle_change(e)
    {
        const view = this;

        view.setState({ is_test: e.target.checked }, ()=>
        {
            // console.log("is_test: ", view.state.is_test);
        });
    }

    handle_send(event)
    {
        const view = this;

        event.preventDefault();
        const { selected_file, is_test, client_label, csv_data } = this.state;

        if(!selected_file)
        {
            alert("Please select a CSV file to upload.");
            return;
        }

        if((csv_data != null) && (client_label != null))
        {
          const form_data = new FormData();
          form_data.append("file", selected_file);
          form_data.append("is_test", is_test);

          // Simulating file upload process (replace with actual API request)
          console.log("Uploading file for testing:", is_test);
          console.log("File Name:", selected_file.name);

          const upload_data=
          {
            data: csv_data,
            client_label: client_label,
            is_test: is_test
          };

          Axios.Post('/api/lead_csv_upload/' + client_label, upload_data, async(response)=>
          {
              console.log("csv_upload response: ", response);
              if(view.state.uploaded_callback != null)
              {
                view.state.uploaded_callback(selected_file.name, csv_data);
              }
          });
        }
    }

    render_checkbox(id, name, label, checked)
    {
      const view = this;

      if(!view.state.force_is_test)
      {
        return (
          <Col md={3}>
            <div className="custom-control ">
             <input type="checkbox"
                    className="custom-control-input"
                    id={id}
                    name={name}
                    label={name}
                    onChange={view.handle_toggle_change}
                    checked={checked}/>
             <label className="custom-control-label" htmlFor={id}>{label}</label>
           </div>
          </Col>
        );
      }
    }

// <Checkbox name="Devvelopment" label="For Development" on_change={view.handle_toggle_change}/>

    render()
    {
      const view = this;

      const {is_test} = view.state;

      // <Form.Group>
      //   {view.render_checkbox("sfgsf", "Development", "For Development", is_test)}
      // </Form.Group>

      return (
          <Container className="csv-upload-container">
              <h2>Upload CSV File of Leads</h2>
              <Form onSubmit={this.handle_send}>
                  <Form.Group>
                      <Form.Label>Select CSV File</Form.Label>
                      <Form.Control
                          type="file"
                          accept=".csv"
                          onChange={this.handle_file_change}
                      />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                      Upload
                  </Button>
              </Form>
          </Container>
      );
    }
}

export default CSVUpload;

import React from 'react';
import BasicTable from './BasicTable';
import getHistory from '../History';

class LinkTable extends BasicTable
{
    constructor(props)
    {
        super(props);

        this.handle_click = this.handle_click.bind(this);

        this.state =
        {
            ...this.state,
            ...this.props,
            owner_view:props.owner_view,
            select_enabled: props.select_enabled,
            title:props.title,
            max_num_results: props.max_num_results,
            max_num_page_results: props.max_num_page_results,
            allow_search: props.allow_search
        }
    }

    handle_click(e, view, prop)
    {
      if(e)
      {
          e.preventDefault();
      }

      var row_index = e.currentTarget.rowIndex;
      if(row_index == null)
      {
        row_index = e.target.parentElement.rowIndex;
      }

      if(this.props.select_enabled)
      {
          const history = getHistory();
          const id = view.state.body_array[row_index-1].id;
          history.push(view.props.item_url + id);
      }
    }

    get_label(label_prefix, currency)
    {
        return label_prefix + " " + currency;
    }

    render_table_row(prop,key)
    {
        var view = this;

        return (
            <tr className="link_table_row" key={key} onClick={(e)=>{view.handle_click(e, view, prop)}}>
            {
                prop.map((prop,key)=> {
                    return ( <td  key={key}>{prop}</td>);
                })
            }
            </tr>
        )
    }
}

LinkTable.defaultProps =
{
  ...BasicTable.defaultProps,
  title: "",
  sub_title: "",
  start_index: 0,
  select_enabled: true,
  allow_search: false
}

export default LinkTable;

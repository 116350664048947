import React from 'react';
import ASComponent from './ASComponent';
import { Table, Row, Col } from 'react-bootstrap';
import ASButton from './ASButton';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import $ from "jquery";

class BasicTable extends ASComponent
{
    constructor(props)
    {
      super(props);

      var total_num_transactions = props.total_num_transactions;
      if((total_num_transactions == null) && (props.body_array != null))
      {
        total_num_transactions = props.body_array.length;
      }

      var view = this;

      var filter_title = view.props.filter_title;
      var search_function = view.on_filter_button_click;
      if(view.props.search_url != null)
      {
        filter_title = "Search";
        search_function = view.on_search_button_click;
      }

      this.state =
      {
        ...this.state,
        title: props.title,
        sub_title: props.sub_title,
        header_array:props.header_array,
        body_array: props.body_array,
        filtered_body_array: props.filtered_body_array,
        filter_title: filter_title,
        search_url:props.search_url,
        search_function: search_function,
        item_not_found: false,
        owner_view:props.owner_view,
        pagination_enabled: props.pagination_enabled,
        max_num_results: props.max_num_results,
        max_num_page_results: props.max_num_page_results,
        start_page: props.start_page,
        num_pages: props.num_pages,
        allow_search: props.allow_search,
        selectable: props.selectable,
        handle_select: ((props.handle_select != null) ? props.handle_select : this.handle_select),
        selected_items: [],
        process_enabled: (props.process_enabled != null) ? props.process_enabled : false,
        process_label: "Process",
        on_process: null
      };

      if(view.state.selectable)
      {
        var found_selected = false;
        for(var i=0; i <   view.state.header_array.length; ++i)
        {
          if(view.state.header_array[i] == "Selected")
          {
            found_selected = true;
            break;
          }
        }
        if(!found_selected)
        {
          view.state.header_array.push("Selected");
        }
      }

      view.get_num_pages = view.get_num_pages.bind(this);
      view.set_table_data = view.set_table_data.bind(this);
      view.format_row = view.format_row.bind(this);

      view.on_filter_button_click = view.on_filter_button_click.bind(view);
      view.on_search_button_click = view.on_search_button_click.bind(view);
      view.on_clear_filter_button_click = view.on_clear_filter_button_click.bind(view);
      view.on_input_change = view.on_input_change.bind(view);
      view.set_table_data = view.set_table_data.bind(view);
      view.on_click_home = view.on_click_home.bind(this);
      view.on_click_prev = view.on_click_prev.bind(this);
      view.on_click_next = view.on_click_next.bind(this);
      view.on_click_end = view.on_click_end.bind(this);

      view.handle_click = view.handle_click.bind(this);
      view.handle_select = view.handle_select.bind(this);

      view.find_item = view.find_item.bind(this);
      view.find_selected_item = view.find_selected_item.bind(this);
      view.clear_selected_items = view.clear_selected_items.bind(this);
      view.on_item_select = view.on_item_select.bind(this);

      // view.on_click_process = view.on_click_process.bind(this);
      view.render_process_button = view.render_process_button.bind(this);
    }

    componentDidMount()
    {
      var view = this;

      view.set_table_data(0, view.state.body_array);
    }

    fetch_data_range()
    {
    }

    fetch_data(url)
    {
    }

    handle_click()
    {
    }

    handle_select()
    {
    }

    find_item(id)
    {
      const view = this;

      for(let item of view.state.body_array)
      {
        if(item.id === id)
        {
          return item;
        }
      }
      return null;
    }

    find_selected_item(id)
    {
      const view = this;

      for(var i=0; i < view.state.selected_items.length; ++i)
      {
        const item = view.state.selected_items[i];
        if(item.id === id)
        {
          return i;
        }
      }
      return -1;
    }

    clear_selected_items()
    {
        const view = this;
        view.setState({ ...view.state, selected_items: [] }, ()=>{
          view.setState({ ...view.state, selected_items: [] }, ()=>{
            window.location.reload(true);
          });
        });
    }

    on_item_select(e)
    {
      const view = this;

      const id = e.currentTarget.id
      if(e.target.checked)
      {
        if(view.find_selected_item(id) == -1)
        {
          const item = view.find_item(id);
          if(item != null)
          {
            view.state.selected_items.push(item);
          }
          else
          {
            console.log("Item " + id + " NOT FOUND!");
          }
        }
      }
      else
      {
        const index = view.find_selected_item(id);
        if(index != -1)
        {
          view.state.selected_items.splice(index, 1);
        }
      }
    }

    set_table_data(start_page, data)
    {
      var view = this;

      if(data != null)
      {
        if(view.state != null)
        {
          const max_num_page_results = view.state.max_num_page_results;
          const num_pages = view.get_num_pages(data);

          // const num_entries = (data.length < max_num_page_results) ? data.length : max_num_page_results;

          const start_entry = start_page * max_num_page_results;
          var num_entries = data.length - start_entry;
          if(num_entries > max_num_page_results)
          {
            num_entries = max_num_page_results;
          }

          const end_entry = start_entry + num_entries;

          var page_data = [];

          for(var i=start_entry; i < end_entry; ++i)
          {
              const formatted_row = view.format_row(data[i]);
              page_data.push(formatted_row);
              if((i-start_entry) == max_num_page_results)
              {
                  break;
              }
          }

          view.setState({ ...view.state, body_array: data, filtered_body_array: page_data, start_page, num_pages });

          return page_data;
        }
      }
      return null;
    }

    format_row(row)
    {
      return null;
    }

    get_num_pages(data)
    {
      var view = this;

      if(data != null)
      {
        const max_num_page_results = this.state.max_num_page_results;
        if(data.length < max_num_page_results)
        {
          return 1;
        }

        const div = Math.floor(data.length / this.state.max_num_page_results);
        var mod = data.length % this.state.max_num_page_results;
        var num_pages = div;
        if((mod > 0) && (mod < this.state.max_num_page_results))
        {
          mod = 1;
        }
        num_pages += mod;

        return num_pages;
    }
      return 0;
    }

    render_table_header()
    {
      const view = this;

      if(view.state != null)
      {
        if(view.state.header_array != null)
        {
          return (
                <thead>
                    <tr>
                    {
                        view.state.header_array.map((prop, key) =>
                        {
                            return ( <th key={key}>{prop}</th> );
                        })
                    }
                    </tr>
                </thead>
            );
          }
        }
    }

    render_table_row(prop, key, num_keys, data)
    {
        var view = this;

        const data_id = data.id;
        return (
            <tr className="link_table_row" key={key} >
            {
                prop.map((prop,key)=>
                {
                  if(view.state.selectable && (key == num_keys-1))
                  {
                    return ( <td key={key}>
                              <Checkbox id={data_id} key={key} name={key} on_change={view.state.on_item_select}/>
                            </td> );
                  }
                  return ( <td key={key}  onClick={
                      (e)=>
                      {
                        view.handle_click(e, view, prop)
                      }
                    }> {prop} </td>);
                })
            }
            </tr>
        )
    }

    render_table_body()
    {
      const view = this;

      if(view.state != null)
      {
        if(view.state.filtered_body_array != null)
        {
          if(view.state.filtered_body_array.length > 0)
          {
              const num_keys = view.state.filtered_body_array[0].length;

              return (
                  <tbody>
                  {
                      view.state.filtered_body_array.map((prop, key) =>
                      {
                          // console.log("PROP: " + prop);
                          // console.log("KEY: " + key);
                          const data = view.state.body_array[key];
                          return view.render_table_row(prop,key, num_keys, data);
                      })
                  }
                  </tbody>
              );
          }
        }
      }
    }

    on_search_button_click(e)
    {
      e.preventDefault();

      var view = this;

      var filtered = [];

      if(view.props.search_url != null)
      {
        var search_data = [];
        search_data.push(view.filter_val);

        Axios.Post(view.props.search_url, {search_data:search_data}, function(response)
		    {
          const http_code = parseInt(response.data.http_code);
          if(http_code === 200)
          {
            const transaction_data = response.data.json_body;

            // console.log(transaction_data);

            if(view.props.parent != null)
            {
              const formatted = view.props.parent.format_search_result(transaction_data);
              view.setState({filtered_body_array: formatted, item_not_found: false});
            }

            /*
            view.setState({ model: transaction_data}, ()=>{
              clearInterval(view.get_data_timer);

              $('#buy_crypto_page').hide();
              $('#buy_crypto_success_page').show();
            });
            */


            // var bitcoin_amount = $("#bitcoin_amount");
            // bitcoin_amount.html(transaction_data.quantity_requested.toFixed(8));
            // $("#transaction_cost").html(transaction_data.transaction_cost.toFixed(8));
          }
        },
        function(error)
        {
          // view.Logout();
          console.log(error);
        });
      }
    }

    on_filter_button_click(e)
    {
      e.preventDefault();

      var view = this;

      var filtered = view.filter_array(view.state.body_array, view.filter_val);
      if(filtered)
      {
        view.setState({filtered_body_array: filtered, item_not_found: false});
      }
      else
      {
        view.setState({filtered_body_array: view.state.body_array, item_not_found: true});
      }
    }

    filter_array(farray, filter_val)
    {
      var found = false;
      var filtered = [];

      for(let item of farray)
      {
        Object.keys(item).forEach(function(index)
        {
          const member = item[index];
          if(member != null)
          {
            const value = member.toString();
            // console.log(`key=${e} value=` + value)

            if(value.indexOf(filter_val) != -1)
            {
              var found_item = false;
              for(let item2 of filtered)
              {
                  if(item == item2)
                  {
                    found_item = true;
                    break;
                  }
              }

              if(!found_item)
              {
                filtered.push(item);
              }
              found = true;
            }
          }
        });
      }

      if(found)
      {
        return filtered;
      }
      return null;
    }

    on_clear_filter_button_click(e)
    {
      e.preventDefault();

      var view = this;

      view.setState({filtered_body_array: view.state.body_array, item_not_found: false});
      $('.transaction_filter_input').val("");
    }

    on_input_change(e)
    {
      e.preventDefault();

      var view = this;

      view.filter_val = e.target.value.trim();
    }

    on_click_home(e)
    {
      e.preventDefault();

      var view = this;  if(view.state != null)
      {
        if((view.state.owner_view != null) && (view.state.body_array != null))
        {
          if(view.state.owner_view.on_table_home_click != null)
          {
            var start_index = 0;
            view.setState({start_index:start_index, max_num_results:view.state.max_num_results}, ()=>{
              view.state.owner_view.on_click_prev(view);
            });
          }
        }
      }
    }

    on_click_prev(e)
    {
      e.preventDefault();

      var view = this;
      if(view.state != null)
      {
        const data = view.state.body_array;
        const num_pages = view.state.num_pages;
        if((view.state.body_array != null) && (num_pages> 0))
        {
          var start_page = view.state.start_page;

          start_page -= 1;
          if(start_page < 0)
          {
            start_page = 0;
          }

          view.set_table_data(start_page, data);
        }
      }
    }

    on_click_next(e)
    {
      e.preventDefault();

      var view = this;
      if(view.state != null)
      {
        const data = view.state.body_array;
        const num_pages = view.state.num_pages;
        if((view.state.body_array != null) && (num_pages> 0))
        {
          var start_page = view.state.start_page;

          start_page += 1;
          if(start_page > num_pages-1)
          {
            start_page = num_pages-1;
          }

          view.set_table_data(start_page, data);
        }
      }
    }

    on_click_end(e)
    {
      e.preventDefault();

      var view = this;
      if(view.state != null)
      {
        if((view.state.owner_view != null) && (view.state.body_array != null))
        {
          if(view.state.owner_view.on_table_prevnext_click != null)
          {
            view.state.owner_view.update_table_end(view, view.state.start_index, view.state.max_num_results);
          }
        }
      }
    }

    /*
    on_click_process(e)
    {
      e.preventDefault();

      var view = this;
      if(view.state != null)
      {

      }
    }
    */

    render_filter_item_not_found()
    {
      var view = this;

      if(view.state.item_not_found)
      {
        return (
          <p style={{color:"red"}}>Item not found!</p>
        );
      }
    }

    render_filter()
    {
      var view = this;

      if(view.state.allow_search)
      {
        return (
          <React.Fragment>
            <div className="basic_table_filter">
              <br/>
              <h4 style={{fontSize:18}}>{view.state.filter_title}</h4>
              <span>
                <label style={{marginRight:30}}>Search by entering column values</label>
                <input className="transaction_filter_input" onChange={this.on_input_change}/>
                <ASButton color="dark" on_click={view.state.search_function} label={view.state.filter_title} id="filter_button"></ASButton>
                <ASButton color="dark" on_click={view.on_clear_filter_button_click} label="Clear" id="filter_button"></ASButton>
              </span>
              {view.render_filter_item_not_found()}
            </div>
          </React.Fragment>
        );
      }
    }

    render_table_buttons()
    {
      var view = this;

      if(view.state != null)
      {
        if((view.state.filtered_body_array != null) /*&& this.props.pagination_enabled*/)
        {
          // if(view.state.filtered_body_array.length > 1)
          {
            return (
              <React.Fragment>
                <Row>
                  <ASButton label="<" extra_classes="bottom_row_button table_button_left" on_click={view.on_click_prev}/>
                  <ASButton label=">" extra_classes="bottom_row_button table_button_right" on_click={view.on_click_next}/>
                  {view.render_process_button()}
                </Row>
              </React.Fragment>
            );
          }
        }
      }
    }

    render_process_button()
    {
      var view = this;

      if((view.state != null) && (view.state.process_enabled) && (view.state.on_process != null))
      {
        return <ASButton label={view.state.process_label}
                         extra_classes="bottom_row_button table_button_right table_process_button_right ml-auto"
                         on_click={view.state.on_process}/>
      }
    }

    render_select_checkbox(key, name, label)
    {
      const view = this;

      return (
        <React.Fragment>
            <Checkbox id={key} name={name} label={label}/>
        </React.Fragment>
      )
    }

    render()
    {
        var view = this;

        const compStyle = { padding: 20 };
        return (
          <React.Fragment>
              <div className="table_header"><h4 className="table_title">{view.props.title}</h4>
              <p className="category">{view.props.sub_title}</p></div>
              {view.render_filter()}
              <div style={{marginTop:20}}></div>
                <Table >
                  {view.render_table_header()}
                  {view.render_table_body()}
                </Table>
              {view.render_table_buttons()}
          </React.Fragment>
        );
    }
}

BasicTable.defaultProps=
{
  filter_title: "Filter",
  title: "",
  sub_title: "",
  start_index: 0,
  max_num_page_results: 10, // Max number of results per table page
  max_num_results: 100, // Max number of results in total
  start_page:0,
  num_pages: 0,
  pagination_enabled: true,
  filtered_body_array: [],
  body_array: [],
  header_array: [],
  allow_search: false
};

export default BasicTable;

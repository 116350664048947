import React, {Component } from "react";
import Message from "./Message";
import Axios from "../utils/Axios";
import Constants from "../Constants";
import $ from "jquery";

class MessagesView extends React.Component
{
  constructor(props)
  {
    super();

    this.state =
    {
      is_live: props.is_live,
      client_lead_id: props.client_lead_id,
      messages: props.messages,
      pollingIntervalId: -1,
      run_state: "",
      messages: (props.messages != null) ? props.messages : [],
      parent_view: props.parent_view
    };

    this.start_polling = this.start_polling.bind(this);
    this.add_fake_message = this.add_fake_message.bind(this);

    this.fetch_messages = this.fetch_messages.bind(this);
    this.scroll_to_bottom = this.scroll_to_bottom.bind(this);
    this.render_message = this.render_message.bind(this);

    this.scroll = React.createRef();
  }

  async componentDidMount()
  {
    const _this = this;

    const parent_view = _this.state.parent_view;
    if((parent_view != null) && (_this.state.messages.length == 0))
    {
      if(parent_view.state.conversation_started)
      {
        await _this.start_polling();
      }
      else
      {
        const url_params = _this.state.client_lead_id;
        const url = '/api/get_first_question/' + url_params;

        var messages = [];
        await Axios.Get('/api/get_first_question/', url_params, async(response)=>
        {
          if(response != null)
          {
            const first_question = response.data;
            await _this.add_fake_message("Assistant", first_question.message);
            console.log("first_question: ", first_question);
          }
        });
      }
    }

     window.scrollTo(0, 0);
  }

  async componentWillUnmount()
  {
    const _this = this;

    console.log("Clearing polling interval...");
    clearInterval(_this.state.pollingIntervalId);
    _this.setState({..._this.state, pollingIntervalId: null});
  }

  async add_fake_message(participant, fake_message)
  {
    const _this = this;

    if(participant == "User")
    {

    }

    var messages = _this.state.messages;
    const message=
    {
      id: "1234",
      name: participant,
      text:
      {
        value: fake_message
      },

    };

    messages.push(message);
    const last_user_message = (participant == "User") ? fake_message : null
    await _this.setState({..._this.state, messages, last_user_message});
  }

  async start_polling(runId: string)
  {
      const _this = this;

      const parent_view = _this.state.parent_view;

      const client_lead_id = _this.state.client_lead_id;
      if(client_lead_id != null)
      {
          var polling = false;
          const intervalId = setInterval(async () =>
          {
            if(!polling)
            {
                 try
                 {
                   polling = true;
                   await Axios.Get('/api/get_run/', client_lead_id, async(response)=>
                   {
                     polling = false;
                     const messages_list = this.scroll.current;
                     const current_messages = _this.state.messages;

                     if(response.not_ready == null)
                     {
                       const updated_run = response.data;
                       const num_messages = current_messages.length;

                       await _this.fetch_messages((messages)=>
                       {
                         const num_new_messages = messages.length;
                         console.log("num_new_messages: ", num_new_messages);

                         if(num_new_messages > 0)
                         {
                           // Handle case where there is only one Assistant message received,
                           // but OpenAI has not yet received our first user message
                           if((num_new_messages == 1) && (_this.state.last_user_message != null))
                           {
                             const fake_msg =
                             {
                               name: "User",
                               text: { value: _this.state.last_user_message },
                               updatedAt: messages[0].updatedAt
                             }
                             messages.push(fake_msg);
                           }
                           /*
                           else
                           if((num_new_messages == 2) && (current_messages[num_messages-1].name == "User"))
                           {
                             const pending_msg =
                             {
                               name: "Assistant",
                               text: { value: "PENDING" },
                               updatedAt: messages[num_messages-1].updatedAt
                             }
                             messages.push(pending_msg);
                           }
                           */

                           _this.setState({..._this.state,
                             run: updated_run,
                             run_state:updated_run.status,
                             messages: messages,
                             pollingIntervalId: intervalId,
                             scroll_height: messages_list.clientHeight,
                             messages_list: messages_list
                           }, async()=>
                           {
                             parent_view.on_fetched_messages(messages);
                             if((_this.scroll != null) &&
                                (_this.scroll.current != null) /*&&
                                (parent_view.state.do_scroll)*/)
                             {
                               _this.state.messages_list.scrollTop = _this.state.messages_list.scrollHeight;
                             }
                           });
                         }
                       });
                     }
                  },
                  (error)=>
                  {
                    polling = false;
                  });
               }
               catch (error)
               {
                 polling = false;
                 console.error("Error polling run status:", error);
                 clearInterval(intervalId);
                 _this.setState({..._this.state, pollingIntervalId: null});
               }
            }
          }, 100);
      }
  };

  async fetch_messages(on_success)
  {
    const _this = this;

    const client_lead_id = _this.state.client_lead_id;
    if(client_lead_id != null)
    {
       try
       {
          await Axios.Get('/api/get_messages/', client_lead_id, (response)=>
          {
             var messages = response.data;
             messages = messages.sort(
               (a, b) =>
                 new Date(a.created_at).getTime() -
                 new Date(b.created_at).getTime()
             );

              messages.forEach((message) =>
              {
                if(message.role == "assistant")
                {
                  message.name = "Assistant";
                }
                else
                {
                  message.name = "User";
                }

                if(message.content != null)
                {
                  if(message.content.length > 0)
                  {
                    message.text = message.content[0].text;
                  }
                }
              });

            if(messages.length == 0)
            {
              console.log("Messages empty!");
            }

            on_success(messages);
        });
       }
       catch (error)
       {
         console.log("error", error);
       }
     }
 };

  scroll_to_bottom()
  {
    this.scroll.current?.scrollIntoView({ behavior: 'smooth' })
  }

  render_message(message, messages, index)
  {
    const _this = this;

    return (
      <React.Fragment>
        <Message key={message.id} message={message} />
      </React.Fragment>
    );
  }

  render()
  {
    const _this = this;

    const {messages} = _this.state;
    // if(messages.length > 0)
    {
      return (
        <div ref={_this.scroll} className="messages-wrapper" >
          {messages?.map((message, index) => (
            _this.render_message(message, messages, index)
          ))}
        </div>
      );
    }
  }
};


export default MessagesView;

import React, { Component } from "react";
import {Row, Col, Card, FormControl, Button} from 'react-bootstrap';
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

class ASComponent extends Component
{
  static s_logged_in_user = null;

  constructor(props)
  {
    super(props);

    this.state=
    {
      dummy: "",
      token: Cookies.get('token')
    }

    this.convert_to_utf8 = this.convert_to_utf8.bind(this);
    this.get_client = this.get_client.bind(this);
    this.get_client_by_label = this.get_client_by_label.bind(this);
    this.get_connections = this.get_connections.bind(this);
  }

  componentDidMount()
  {
    this.setState({token: Cookies.get('token')});
  }

  convert_to_utf8(s)
  {
    for(var c, i = -1, l = (s = s.split("")).length, o = String.fromCharCode; ++i < l;
      s[i] = (c = s[i].charCodeAt(0)) >= 127 ? o(0xc0 | (c >>> 6)) + o(0x80 | (c & 0x3f)) : s[i]
    );
    return s.join("");
  }

  async get_client(client_id, on_success)
  {
    const view = this;

    if(client_id != null)
    {
      await Axios.Get('/api/client/', client_id, async(response)=>
      {
        if(response.data != null)
        {
          view.setState({...view.state, client: response.data}, ()=>
          {
            if(on_success != null)
            {
                on_success(view.state.client);
            }
          });
        }
      });
    }
  }

  async get_client_by_label(client_label, on_success)
  {
    const view = this;

    if(client_label != null)
    {
      await Axios.Get('/api/client_by_label/', client_label, async(response)=>
      {
        if(response.data != null)
        {
          view.setState({...view.state, client: response.data}, ()=>
          {
            if(on_success != null)
            {
                on_success(view.state.client);
            }
          });
        }
      });
    }
  }

  clear_token_cookie()
  {
    const view = this;

    const token = view.get_token();
    console.log("clear_token_cookie, token:");
    console.log(token);

    Cookies.remove('token');

    view.update_token(null);

    // ASComponent.s_logged_in_user = null;
    view.setState({loggedin_user: ASComponent.s_logged_in_user});
  }

  get_token(allow_logout = true)
  {
    return Cookies.get('token');
  }

  update_token(token)
  {
    var self = this;

    Cookies.set('token', token);

    self.setState({
      token: token
    });

    if(self.parent != null)
    {
      self.parent.update_token(token);
    }
  }

  get_connections(success)
  {
    var view = this;

    Axios.Get('/api/connections', null, function(response)
    {
        if(response.data != null)
        {
            const response_data = response.data;
            const agents = response_data.agents;

            var connections = [];
            const num_agents = agents.length;
            for(var i=0; i < num_agents; ++i)
            {
              const agent = agents[i];
              if(agent.connections != null)
              {
                for(var j=0; j < agent.connections.length; ++j)
                {
                  const connection = agent.connections[j];
                  connections.push(connection);
                }
              }
            }

            view.setState({...view.state, agents, connections}, ()=>
            {
              if(success != null)
              {
                success(agents);
              }
            });
        }
    });
  }

  get_users(success)
  {
    var view = this;

    Axios.Get('/api/users', null, function(response)
    {
        if(response.data != null)
        {
            const response_data = response.data;

            /*
            const agents = response_data.agents;

            var users = [];
            const num_agents = agents.length;
            for(var i=0; i < num_agents; ++i)
            {
              const agent = agents[i];
              if(agent.users != null)
              {
                for(var j=0; j < agent.users.length; ++j)
                {
                  const user = agent.users[j];
                  users.push(user);
                }
              }
            }

            view.setState({...view.state, agents, users}, ()=>
            {
              if(success != null)
              {
                success(agents);
              }
            });
            */
        }
    });
  }

  atob(ascii)
  {
      return Uint8Array.from(atob(ascii), c => c.charCodeAt(0));
  }

  btoa(buffer)
  {
      var binary = [];
      var bytes = new Uint8Array(buffer);
      for (var i = 0, il = bytes.byteLength; i < il; i++)
      {
          binary.push(String.fromCharCode(bytes[i]));
      }
      return btoa(binary.join(''));
  }

  format_date(date_string)
  {
    const date = new Date(date_string);

    var day = date.getDate();
    if(parseInt(day) < 10)
    {
      day = "0" + day;
    }

    var month = date.getMonth() + 1;
    if(parseInt(month) < 10)
    {
      month = "0" + month;
    }

    const formatted_date = day + "-" + month + "-" + date.getFullYear()
    // console.log(formatted_date);

    return formatted_date;
  }

  format_date_time(date)
  {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;

    const strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
  }

  render()
  {
    const view = this;
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

ASComponent.defaultProps=
{
  title: "TITLE"
}

export default ASComponent;

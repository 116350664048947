import React from 'react';
import { Modal } from 'react-bootstrap';
import TableBase from './TableBase';
import LeadEdit from './LeadEdit'; // Assuming LeadEdit component is in the same folder
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import { GlobalHistory } from '../History';
import getHistory from '../History';


class LeadTable extends TableBase
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      ...this.state,
      client_label: props.client_label,
      data: props.data,
      show_modal: false,
      fetch_interval: props.fetch_interval,
      page: 0,
      pagination_limit: 10
    };

    this.fetch_data = this.fetch_data.bind(this);
    this.navigate_to_lead_view = this.navigate_to_lead_view.bind(this);
  }

  async componentDidMount()
  {
    const view = this;

    await view.setState({pagination_limit: 10});
    super.componentDidMount();
  }

  async fetch_data(on_success)
  {
    const view = this;

    const { loggedin_user, client_label, pagination, pagination_limit } = this.state;

    const token = Cookies.get("token");
    const url = "/api/get_leads/" + view.state.client_label;

    const post_data =
    {
      token,
      client_label: client_label,
      page: pagination,
      limit: pagination_limit
    };

    await Axios.Post(url, post_data, async(response)=>
    {
      const array_data = response.data;
      await view.setState({...view.state, data:array_data.data, data_length: array_data.total_data_length});

      if(on_success != null)
      {
        on_success(response);
      }
    });
  }

  handle_page_change(page)
  {
    this.fetch_data(page);
  }

  navigate_to_lead_view(lead_id)
  {
    const history = getHistory();
    history.push("/lead/" + lead_id)
  }

  render_table_header()
  {
    const view = this;

    return (
      <React.Fragment>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Phone Number</th>
        <th>Email</th>
        <th>Status</th>
        <th>Is Test</th>
      </React.Fragment>
    );
  }

  render_table_rows(data)
  {
    const view = this;

    const is_test = data.is_test ? "True" : "False";
    return (
      <React.Fragment>
        <tr key={data.id} className="record-table-row" onClick={() => view.navigate_to_lead_view(data.id)}>
          <td>{data.first_name}</td>
          <td>{data.last_name}</td>
          <td>{data.phone_number}</td>
          <td>{data.email}</td>
          <td>{data.lead_status}</td>
          <td>{is_test}</td>
        </tr>
      </React.Fragment>
    );
  }

  render_modal(show_modal, selected)
  {
    const view = this;

    return (
      <Modal show={show_modal} onHide={this.close_edit_modal}>
        <Modal.Header closeButton>
          <Modal.Title>View Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected &&
            <LeadEdit
              lead={selected}
              onClose={this.close_edit_modal}/>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default LeadTable;

import React from 'react';
import Axios from '../utils/Axios';
import LinkTable from './LinkTable';
import Checkbox from './Checkbox';
import Constants from '../Constants.js';

import "./css/Table.css"

class UsersTable extends LinkTable
{
    constructor(props)
    {
      super(props);

      var view = this;

      view.state =
      {
        ...this.state,
        title:props.title,
        url: props.url,
        body_array: props.body_array,
        header_array: props.header_array,
        search_url: '/find_users/',
        owner_view:props.owner_view,
        max_num_results: props.max_num_results,
        max_num_page_results: props.max_num_page_results,
        user_groups: props.user_groups        
      }

      this.format_row = this.format_row.bind(this);
    }

    find_user_group(usergroupid, user_groups)
    {
      for(let user_group of user_groups)
      {
        if(usergroupid == user_group.id)
        {
          return user_group;
        }
      }
      return null
    }

    format_row(row)
    {
      const view = this;

      if(row != null)
      {
        var row_data = [];

        row_data.push(row.firstname);
        row_data.push(row.lastname);
        row_data.push(row.email);
        row_data.push(row.phone);
        row_data.push(row.address1);
        row_data.push(row.address2);
        row_data.push(row.country);
        row_data.push(row.zip);

        if(view.state.user_groups != null)
        {
          const user_group = view.find_user_group(row.usergroupid, view.state.user_groups);
          if(user_group != null)
          {
            row_data.push(user_group.name);
          }
          else {
            row_data.push("N/A");
          }
        }
        else
        {
          row_data.push("N/A");
        }

        if(view.state.selectable)
        {
          row_data.push(true);
        }

        return row_data;
      }
      return null;
    }

    format_search_result(results)
    {
      var formatted = [];

      for(let user of results)
      {
        const table_user = [];

        table_user.push(user.firstname);
        table_user.push(user.lastname);
        formatted.push(table_user);
      }
      return formatted;
    }

    render_table_row(prop, key, num_keys, data)
    {
        const view = this;

        const data_id = data.id;
        return (
            <tr className="link_table_row" key={key} >
            {
                prop.map((prop,key)=>
                {
                  if(view.state.selectable && (key == num_keys-1))
                  {
                    return ( <td key={key}>
                              <Checkbox id={data_id} key={key} name={key} on_change={view.on_item_select}/>
                            </td> );
                  }
                  return ( <td key={key} onClick=
                    {
                      (e)=>
                      {
                        view.handle_click(e, view, prop)
                      }
                    }> {prop} </td>);
                })
            }
            </tr>
        )
    }
}

UsersTable.defaultProps =
{
  ...LinkTable.defaultProps,
  url: "/users/",
  search_url: '/find_users/',
  item_url: '/user/',
  header_array:["First Name","Last Name", "Email", "Phone", "Address 1", "Address 2", "Country", "Zip", "User Group"],
  start_index: 0
}

export default UsersTable;

import React, { Component } from 'react';
import UserPermissionsView from './UserPermissionsView';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';

class PermissionedView extends UserPermissionsView
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      ...this.state,
      user: null,
      user_group: null,
      user_group_changed: false,
      user_permissions_changed: false,
    }
  }

  /*
  async componentDidMount(on_success)
  {
    const view = this;

    view.state.user = null;
    view.state.user_group = null;

    await view.get_logged_in_user(async(token, user)=>
    {
      if(on_success != null)
      {
        on_success(token, user);
      }

      // const token = view.state.app.state.token;
      const loggedin_user = view.state.loggedin_user;
      if(loggedin_user)
      {
        await view.get_permissions(loggedin_user.id, on_success);
      }
    },
    (error)=>
    {

    },
    ()=>
    {

    });
  }
  */

  render_view()
  {
    const view = this;

    return <div/>
  }
}


export default PermissionedView;
